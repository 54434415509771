.software-list {
    display: flex;
    flex-direction: column;
}

.software-list > div {
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(218,218,218,1);
}
.software-list h2 {
    margin-bottom: 1rem;
}
.software-list > div:hover, .software-list > div.selected {
    background: rgba(123, 123, 123, 0.05);
    cursor: pointer;
}