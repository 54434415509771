.AddStepButton {
    border: 1px dashed rgba(var(--palette-neutral-30,166, 166, 166),1);
    color: rgba(var(--palette-neutral-30,90, 90, 90),1);
    width: 190px;
    text-align: center;
    padding: 2rem 0;
    cursor: pointer;
    float: left;
    box-sizing: border-box;
    margin: auto;
    border-radius: 5px;
}

.AddStepButton p {
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.AddStepButton span {
    font-weight: bold;
}

.AddStepButton:hover {
    background: #e0e0e0;
    border: 1px dashed #333;
    color: #333;
    cursor: pointer;
}