.engrator-right-panel {
    position: absolute;
    right: 0;
    top: 4rem;
    bottom: 0;
    width: 20rem;
    background: #fff;
    box-shadow: -3px 0 8px 0 rgba(66,91,118,.21);
}

.engrator-right-panel .close-btn {
    cursor: pointer;
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 1.3em;
    color: #fff;
}

.engrator-right-panel .header {
    background: #1366a5;
    padding: 1rem;
    font-size: 1.1em;
    color: #fff;
}

.engrator-right-panel .content {
    padding: 1rem;
}