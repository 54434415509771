.engrator-tab-bar {
    display: block;
}

.engrator-tab-bar:after {
    content: "";
    display: block;
    clear: both;
}

.engrator-tab-bar .engrator-tab-button {
    float: left;
}