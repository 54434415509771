@font-face {
  font-family: 'regular-paragraph';
  src: url("./OpenSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'medium';
  src: url("./OpenSans-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'bold';
  src: url("./OpenSans-Bold.woff") format("woff"); }

@font-face {
  font-family: 'regular';
  src: url("./OpenSans-Regular.woff") format("woff"); }

body {
  font-size: 14px;
  text-align: left;
  font-family: 'regular', Arial, Helvetica, sans-serif;
  padding: 20px 0 20px 0;
  margin: 0;
  margin-left: 60px;
  background-color: #f4f4f7;
  color: rgba(0, 0, 0, 0.85); }

html.sign-in-module {
  height: 100%; }
  html.sign-in-module body {
    padding: 0rem;
    height: 100%;
    background: #f3f3f3;
    margin-left: 0px; }

body.noscroll {
  overflow-y: hidden; }

.getintio {
  height: 100%; }
  .getintio a, .getintio button, .getintio input, .getintio select, .getintio textarea {
    font-size: 14px;
    outline: none; }
  .getintio a, .getintio a:hover {
    color: #0050c7; }
  .getintio h1, .getintio h2, .getintio h3, .getintio h4, .getintio h5, .getintio h6 {
    margin: 0; }
  .getintio h1, .getintio h2, .getintio h3, .getintio h4, .getintio h5, .getintio h6, .getintio form label {
    color: rgba(0, 0, 0, 0.95);
    font-family: 'regular', Arial, Helvetica, sans-serif;
    font-weight: normal; }
  .getintio h1 {
    font-size: 21px;
    line-height: 21px; }
  .getintio h2 {
    font-size: 17px; }
  .getintio form label {
    display: block;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-family: 'medium'; }
  .getintio select {
    width: 100%; }
  .getintio span.error {
    color: #ca423d;
    font-size: 12px;
    display: block;
    line-height: 1.25rem;
    padding: 10px 0 0 0; }
  .getintio div.logotype {
    position: relative;
    display: flex;
    flex-direction: row; }
    .getintio div.logotype img {
      top: 8px;
      margin-left: 1.5rem;
      position: relative; }
    .getintio div.logotype h1 {
      margin: 0;
      padding: 0;
      padding-top: 12px;
      padding-left: 10px;
      margin-right: 1.5rem; }
  .getintio .App-link {
    color: #09d3ac; }
  .getintio .flex {
    display: flex; }
    .getintio .flex.row {
      flex-direction: row; }
    .getintio .flex.two-columns {
      column-gap: 15px; }
      .getintio .flex.two-columns > div {
        flex: 1;
        position: relative; }
        .getintio .flex.two-columns > div:first-child {
          box-sizing: border-box; }
        .getintio .flex.two-columns > div:nth-child(2) {
          box-sizing: border-box; }
  .getintio .flex-equal {
    flex: 1;
    position: relative; }
    .getintio .flex-equal.with-padding {
      padding: 15px;
      box-sizing: border-box; }
  .getintio .overlay {
    position: fixed;
    z-index: 99999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.7); }
    .getintio .overlay .inner {
      max-width: 800px;
      width: 100%;
      display: block;
      margin: 100px auto auto;
      background: #fff;
      box-sizing: border-box;
      padding: 20px;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
  .getintio .ui-centered-loader {
    margin: auto;
    display: block;
    text-align: center;
    font-size: 12px; }
  .getintio .failure-reason {
    font-size: 12px; }
    .getintio .failure-reason p {
      margin: 0;
      padding: 0;
      margin-bottom: 5px;
      font-family: 'bold'; }
  .getintio .footer-links {
    text-align: center;
    margin-top: 50px; }
    .getintio .footer-links a {
      font-size: 12px;
      padding: 0 5px; }
  .getintio .engrator-ui-icon img {
    width: 16px;
    height: 16px; }
  .getintio .margin-top {
    margin-top: 15px; }
  .getintio table.engrator-table {
    width: 100%;
    background: #fff;
    border-left: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-radius: 3px;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    font-size: 13px; }
  .getintio table.engrator-table thead tr th {
    border-bottom: 1px solid #eaeaea; }
  .getintio table.engrator-table thead th {
    text-align: left;
    font-family: "medium";
    padding: 1rem 0.5rem; }
  .getintio table.engrator-table thead th.th-actions {
    width: 75px; }
  .getintio table.engrator-table tbody tr td {
    padding: 1rem 0.5rem;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    font-size: 14px; }
  .getintio table.engrator-table tbody tr:hover {
    background-color: rgba(123, 123, 123, 0.05); }
  .getintio .run-results-tab-bar {
    margin-bottom: 2rem;
    display: flex; }
    .getintio .run-results-tab-bar .engrator-tab-bar {
      flex: 1; }
    .getintio .run-results-tab-bar > .button-container button {
      min-width: 4rem; }
    .getintio .run-results-tab-bar .import-btn {
      margin-right: 20px; }
  .getintio .engrator-page.run-results > h3 {
    margin: 20px 0; }
  .getintio .engrator-page.run-results .flows-header {
    margin-top: 20px;
    margin-bottom: 15px; }
    .getintio .engrator-page.run-results .flows-header button {
      float: right; }
  .getintio .engrator-page.run-results .header-tile .engrator-ui-tile {
    display: flex;
    align-items: center; }
    .getintio .engrator-page.run-results .header-tile .engrator-ui-tile > div {
      margin-right: 20px;
      min-width: 250px; }
      .getintio .engrator-page.run-results .header-tile .engrator-ui-tile > div .label-value {
        margin-bottom: 10px;
        font-size: 14px; }
  .getintio .content > .engrator-page.run-details {
    padding-right: 0px;
    padding-top: 0px;
    padding-left: 160px; }
    .getintio .content > .engrator-page.run-details .run-results-logs .engrator-buttons-bar {
      padding-right: 30px; }
  .getintio .engrator-ui-tile {
    width: 100%;
    display: block;
    background-color: #fff;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 4px; }
  .getintio .engrator-ui-tile-header {
    margin-bottom: 15px; }
  .getintio .ui-label-value.inline {
    display: flex; }
  .getintio .engrator-ui-dashboard-tile {
    position: relative;
    width: 50%;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    max-width: 600px; }
    .getintio .engrator-ui-dashboard-tile h3 {
      margin: 0;
      font-family: 'bold';
      font-size: 14px; }
    .getintio .engrator-ui-dashboard-tile div.content {
      margin-top: 1rem;
      justify-content: left; }
    .getintio .engrator-ui-dashboard-tile .footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      margin-top: 1rem;
      align-items: center;
      flex-direction: row; }
      .getintio .engrator-ui-dashboard-tile .footer div.description {
        flex-grow: 2;
        font-size: 12px; }
      .getintio .engrator-ui-dashboard-tile .footer > div {
        flex: 1; }
        .getintio .engrator-ui-dashboard-tile .footer > div .engrator-nav-link {
          float: right; }
  .getintio .engrator-ui-icon svg {
    width: 16px;
    height: 16px; }
  .getintio .engrator-ui-icon-status-success {
    color: #4dad5d;
    font-size: 17px; }
  .getintio .engrator-ui-icon-status-failure {
    color: #ca423d;
    font-size: 17px; }
  .getintio .engrator-ui-icon-status-started {
    color: #0050c7;
    font-size: 17px; }
  .getintio .button-container.elementary.is-loading button {
    color: transparent; }
  .getintio .button-container button,
  .getintio .btn-generic-secondary {
    display: block;
    cursor: pointer;
    border: 0;
    padding: 0rem 1.5rem;
    line-height: 2rem;
    min-height: 32px;
    min-width: 90px;
    position: relative; }
    .getintio .button-container button .engrator-ui-icon-spinner, .getintio .button-container button .engrator-ui-icon-spinnerwhite,
    .getintio .btn-generic-secondary .engrator-ui-icon-spinner,
    .getintio .btn-generic-secondary .engrator-ui-icon-spinnerwhite {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 15px;
      margin-left: -10px;
      margin-top: -14px;
      display: initial !important; }
  .getintio .button-container button:disabled {
    cursor: initial;
    opacity: 0.5; }
  .getintio .button-container.inline button {
    color: #0050c7;
    font-weight: 600;
    padding: 0; }
  .getintio .button-container.short button {
    min-width: 50px; }
  .getintio .button-container.elementary button,
  .getintio .button-container.secondary button,
  .getintio .button-container.danger button,
  .getintio .btn-generic-secondary {
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    border-radius: 4px;
    font-family: 'medium';
    line-height: 35px;
    min-height: auto;
    display: flex;
    align-items: center;
    justify-content: center; }
    .getintio .button-container.elementary button .engrator-ui-icon,
    .getintio .button-container.secondary button .engrator-ui-icon,
    .getintio .button-container.danger button .engrator-ui-icon,
    .getintio .btn-generic-secondary .engrator-ui-icon {
      padding-right: 5px; }
    .getintio .button-container.elementary button .engrator-ui-icon.icon-20,
    .getintio .button-container.secondary button .engrator-ui-icon.icon-20,
    .getintio .button-container.danger button .engrator-ui-icon.icon-20,
    .getintio .btn-generic-secondary .engrator-ui-icon.icon-20 {
      display: flex;
      align-items: center;
      height: 35px; }
      .getintio .button-container.elementary button .engrator-ui-icon.icon-20 svg,
      .getintio .button-container.secondary button .engrator-ui-icon.icon-20 svg,
      .getintio .button-container.danger button .engrator-ui-icon.icon-20 svg,
      .getintio .btn-generic-secondary .engrator-ui-icon.icon-20 svg {
        height: 16px;
        width: auto; }
  .getintio .button-container.elementary button:hover {
    background: #083f69; }
  .getintio .button-container.elementary button {
    background: #0050c7;
    border: 1px solid #003a91;
    color: rgba(255, 255, 255, 0.95); }
    .getintio .button-container.elementary button.noticeable-btn {
      background: #6554c0; }
      .getintio .button-container.elementary button.noticeable-btn:hover {
        background: #230e94; }
  .getintio .button-container button.confirmation-text {
    background: #4dad5d !important;
    color: #fff !important;
    opacity: 1; }
  .getintio .button-container.secondary button,
  .getintio .btn-generic-secondary {
    border: 1px solid #d7d7d7;
    background: #fbfbfb;
    color: rgba(0, 0, 0, 0.95); }
  .getintio .button-container.secondary button:hover,
  .getintio .btn-generic-secondary:hover {
    background: #ccc; }
  .getintio .button-container.inline button:hover {
    text-decoration: underline; }
  .getintio .button-container.danger button {
    background: #ca423d;
    color: rgba(255, 255, 255, 0.95); }
  .getintio .button-container.danger button:hover {
    background: #960c06; }
  .getintio .button-container .icon {
    color: #666;
    padding: 0 0.5rem;
    height: 10px;
    font-size: 12px; }
  .getintio .button-container.link-inline > button {
    color: #0050c7;
    padding: 0;
    margin: 0;
    font-weight: normal;
    font-family: medium;
    min-width: auto;
    background: none; }
    .getintio .button-container.link-inline > button:hover {
      text-decoration: underline; }
  .getintio .button-container.delete.danger button {
    padding: 0 15px;
    min-width: auto; }
  .getintio .engrator-nav-link a {
    color: #0050c7;
    font-family: 'medium';
    padding: 0;
    text-decoration: none; }
  .getintio .engrator-nav-link.button a {
    border: 1px solid #eaeaea;
    background: #fff;
    padding: 15px;
    border-radius: 4px; }
  .getintio .engrator-nav-link.dark a {
    color: #434f5f; }
  .getintio .engrator-nav-link a:hover {
    text-decoration: underline; }
  .getintio .engrator-nav-link.selected a:after {
    content: "";
    border-bottom: 3px solid #1366a5;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0; }
  .getintio input[type="text"],
  .getintio input[type="password"],
  .getintio input[type="number"],
  .getintio textarea {
    width: 100%;
    box-sizing: border-box;
    padding: 7px;
    font-size: 14px;
    outline: none;
    border-width: 1px; }
    .getintio input[type="text"] span.required,
    .getintio input[type="password"] span.required,
    .getintio input[type="number"] span.required,
    .getintio textarea span.required {
      color: #ca423d; }
    .getintio input[type="text"]:focus,
    .getintio input[type="password"]:focus,
    .getintio input[type="number"]:focus,
    .getintio textarea:focus {
      border-color: #0050c7; }
  .getintio select {
    width: 100%;
    box-sizing: border-box;
    padding: 0.25rem;
    font-size: 14px; }
  .getintio input[type="checkbox"] {
    cursor: pointer; }
  .getintio input[type="text"],
  .getintio input[type="password"],
  .getintio input[type="number"],
  .getintio select,
  .getintio textarea {
    border: 2px solid #dfe1e6;
    color: #434f5f;
    border-radius: 3px; }
    .getintio input[type="text"]:hover,
    .getintio input[type="password"]:hover,
    .getintio input[type="number"]:hover,
    .getintio select:hover,
    .getintio textarea:hover {
      border: 2px solid #0050c7; }
  .getintio .ui-input {
    position: relative; }
    .getintio .ui-input.with-icon input {
      padding-left: 30px; }
    .getintio .ui-input img {
      opacity: 40%;
      height: 15px;
      position: absolute;
      bottom: 20px;
      left: 20px; }
  .getintio .ui-dropdown {
    width: 100%; }
    .getintio .ui-dropdown .selected-value {
      cursor: pointer;
      border: 2px solid #dfe1e6;
      border-radius: 3px;
      box-sizing: border-box;
      padding: 6px;
      font-size: 14px;
      background: #fff;
      position: relative; }
      .getintio .ui-dropdown .selected-value .engrator-ui-icon-chevron-down,
      .getintio .ui-dropdown .selected-value .engrator-ui-icon-chevron-up {
        position: absolute;
        right: 10px; }
      .getintio .ui-dropdown .selected-value .empty {
        color: rgba(0, 0, 0, 0.5); }
      .getintio .ui-dropdown .selected-value:hover {
        border: 2px solid #0050c7; }
    .getintio .ui-dropdown .options-list {
      background: #fff;
      border: 1px solid #eaeaea;
      position: absolute;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      width: 100%;
      max-width: 400px;
      z-index: 1; }
      .getintio .ui-dropdown .options-list > div {
        padding: 10px;
        border-bottom: 1px solid #eaeaea; }
      .getintio .ui-dropdown .options-list > span.no-options-available {
        padding: 10px;
        display: block;
        font-size: 14px; }
      .getintio .ui-dropdown .options-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
        max-height: 205px;
        overflow-y: auto; }
        .getintio .ui-dropdown .options-list ul li {
          font-family: 'regular';
          cursor: pointer;
          padding: 0;
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 1px solid #eaeaea;
          padding: 10px;
          font-size: 12px; }
          .getintio .ui-dropdown .options-list ul li .value {
            opacity: 0.7;
            color: rgba(0, 0, 0, 0.5);
            display: none;
            padding-left: 15px; }
          .getintio .ui-dropdown .options-list ul li.hidden {
            display: none; }
          .getintio .ui-dropdown .options-list ul li.not-available {
            color: rgba(0, 0, 0, 0.3);
            cursor: auto !important; }
          .getintio .ui-dropdown .options-list ul li:hover, .getintio .ui-dropdown .options-list ul li.highlighted {
            background: #f3f3f3;
            color: #0050c7; }
            .getintio .ui-dropdown .options-list ul li:hover .value, .getintio .ui-dropdown .options-list ul li.highlighted .value {
              display: inline-block; }
          .getintio .ui-dropdown .options-list ul li.option-element .visible-text,
          .getintio .ui-dropdown .options-list ul li.option-element .value {
            display: none; }
          .getintio .ui-dropdown .options-list ul li div.element-option {
            display: flex;
            align-items: center; }
    .getintio .ui-dropdown.disable-search .options-list > div {
      display: none; }
  .getintio .engrator-form-group {
    margin: 10px 0; }
    .getintio .engrator-form-group p.description {
      font-family: 'regular-paragraph';
      font-size: 12px;
      line-height: 14px;
      color: #434f5f;
      padding: 0 0 0.2rem 0;
      margin: 0 0 5px; }
    .getintio .engrator-form-group span.required {
      color: #ca423d; }
  .getintio label span.required {
    color: #ca423d; }
  .getintio .engrator-form.max-width {
    max-width: 700px;
    width: 100%; }
  .getintio .engrator-form {
    box-sizing: border-box;
    display: block; }
  .getintio .engrator-form-section {
    box-sizing: border-box; }
    .getintio .engrator-form-section h2 {
      margin-top: 20px;
      margin-bottom: 20px; }
    .getintio .engrator-form-section .content {
      padding: 15px;
      border-radius: 4px;
      background: #f3f3f3; }
  .getintio .RightPanel {
    background: #ffffff;
    box-shadow: rgba(var(--palette-neutral-20, 200, 200, 200), 1) -10px 8px 30px 0;
    position: fixed;
    z-index: 10000;
    top: 7rem;
    bottom: 25px;
    right: 25px;
    width: 35rem;
    border-radius: 5px; }
    .getintio .RightPanel .close-btn {
      cursor: pointer;
      float: right;
      position: absolute;
      top: 25px;
      right: 25px;
      z-index: 10000;
      color: #0050c7; }
    .getintio .RightPanel h1 {
      border-bottom: 1px solid #dadada;
      color: #0050c7; }
    .getintio .RightPanel h2 {
      font-size: 1.1rem; }
    .getintio .RightPanel .content {
      height: 100%; }
  .getintio .engrator-right-panel-content {
    position: relative;
    height: 100%; }
    .getintio .engrator-right-panel-content h1 {
      margin: 0;
      padding: 0 1rem;
      height: 4rem;
      line-height: 4rem;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-left: 60px;
      box-shadow: 0px 7px 5px -1px rgba(0, 0, 0, 0.13);
      z-index: 1; }
      .getintio .engrator-right-panel-content h1 > img {
        position: absolute;
        top: 15px;
        left: 15px; }
    .getintio .engrator-right-panel-content div.footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4rem;
      padding: 0 1rem;
      box-shadow: 0px -7px 5px -1px rgba(0, 0, 0, 0.13); }
      .getintio .engrator-right-panel-content div.footer .inner {
        margin-top: 1rem;
        float: right; }
    .getintio .engrator-right-panel-content div.inner-content {
      position: absolute;
      left: 0;
      right: 0;
      top: 4rem;
      bottom: 4rem;
      overflow-y: auto;
      padding: 15px 25px 25px; }
  .getintio .engrator-ui-execution-time {
    color: #434f5f;
    font-size: 12px; }
    .getintio .engrator-ui-execution-time span.time {
      color: rgba(0, 0, 0, 0.5); }
  .getintio .engrator-ui-message {
    line-height: 20px;
    font-size: 14px;
    font-family: 'regular-paragraph';
    box-sizing: border-box; }
    .getintio .engrator-ui-message.error {
      color: #ca423d;
      overflow-wrap: anywhere; }
    .getintio .engrator-ui-message ul {
      padding-left: 15px; }
    .getintio .engrator-ui-message.error-message {
      overflow-wrap: anywhere;
      background-color: #ffebe6;
      border-radius: 5px;
      padding: 15px;
      padding-left: 40px;
      position: relative; }
      .getintio .engrator-ui-message.error-message .engrator-ui-icon-exclamation-triangle {
        color: #ca423d;
        position: absolute;
        top: 15px;
        left: 12px; }
    .getintio .engrator-ui-message.success {
      color: #4dad5d; }
    .getintio .engrator-ui-message.warning {
      background-color: #fffae6;
      border-radius: 5px;
      padding: 15px;
      padding-left: 40px;
      position: relative; }
      .getintio .engrator-ui-message.warning .engrator-ui-icon-exclamation-triangle {
        color: #ef8a33;
        position: absolute;
        top: 15px;
        left: 12px; }
    .getintio .engrator-ui-message.info {
      background: #ddecff;
      color: #172b4d;
      border-radius: 5px;
      padding: 15px;
      margin: 15px 0; }
  .getintio .engrator-ui-entity-status {
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'medium';
    border: 1px solid #e3e3e3; }
    .getintio .engrator-ui-entity-status.disabled {
      background: #f3f3f3; }
    .getintio .engrator-ui-entity-status.enabled {
      background: #eaffee; }
  .getintio .user-role-label {
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'medium';
    background: #f3f3f3; }
  .getintio .engrator-ui-tag {
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'medium';
    background: #f3f3f3;
    border: 1px solid #e3e3e3; }
    .getintio .engrator-ui-tag.transparent-bg {
      background: transparent; }
  .getintio .engrator-ui-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 25px; }
    .getintio .engrator-ui-pagination .page-info {
      padding: 0 25px;
      line-height: 34px; }
    .getintio .engrator-ui-pagination button {
      min-width: 50px; }
  .getintio .engrator-ui-short-explanation {
    display: inline-block;
    position: relative;
    font-size: 14px;
    color: #0050c7; }
    .getintio .engrator-ui-short-explanation .tooltip {
      font-size: 12px;
      background-color: rgba(0, 0, 0, 0.95);
      color: #fff;
      border-radius: 4px;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      padding: 10px;
      position: absolute;
      top: 20px;
      left: 20px;
      width: 13rem;
      display: block;
      word-break: break-word; }
  .getintio .engrator-ui-documentation-link {
    color: #0050c7;
    font-size: inherit;
    text-decoration: none; }
  .getintio .engrator-ui-logs-viewer {
    font-size: 12px;
    max-width: 100%;
    padding: 15px;
    overflow: auto;
    line-height: 20px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.95);
    border: 1px solid #eaeaea;
    margin-top: 100px; }
    .getintio .engrator-ui-logs-viewer pre {
      margin: 0; }
      .getintio .engrator-ui-logs-viewer pre.wrapped {
        white-space: pre-wrap;
        /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;
        /* Mozilla, since 1999 */
        white-space: -o-pre-wrap;
        /* Opera 7 */
        word-wrap: break-word; }
      .getintio .engrator-ui-logs-viewer pre p {
        position: relative;
        padding: 3px 0 3px 45px;
        word-wrap: anywhere;
        cursor: pointer;
        margin: 0; }
        .getintio .engrator-ui-logs-viewer pre p a {
          font-size: 12px; }
        .getintio .engrator-ui-logs-viewer pre p .line-number {
          color: rgba(var(--palette-neutral-60, 102, 102, 102), 1);
          text-align: right;
          min-width: 33px;
          margin-right: 10px;
          display: inline-block;
          position: absolute;
          left: 0;
          top: 3px; }
        .getintio .engrator-ui-logs-viewer pre p.debug {
          color: gray; }
        .getintio .engrator-ui-logs-viewer pre p.error {
          color: red; }
        .getintio .engrator-ui-logs-viewer pre p.warn {
          color: yellow; }
        .getintio .engrator-ui-logs-viewer pre p.executing-step {
          color: #667fff; }
        .getintio .engrator-ui-logs-viewer pre p a {
          color: #66bdff; }
        .getintio .engrator-ui-logs-viewer pre p.performing-flow {
          color: #73e58c; }
        .getintio .engrator-ui-logs-viewer pre p.blue {
          color: #73e58c; }
        .getintio .engrator-ui-logs-viewer pre p:hover {
          background-color: rgba(255, 255, 255, 0.1); }
    .getintio .engrator-ui-logs-viewer.no-interactions {
      margin-top: 0; }
      .getintio .engrator-ui-logs-viewer.no-interactions pre p {
        cursor: initial; }
        .getintio .engrator-ui-logs-viewer.no-interactions pre p:hover {
          background-color: initial; }
  .getintio .engrator-ui-logs-search {
    position: fixed;
    top: 0;
    background-color: #fff;
    right: 0;
    left: 220px;
    box-shadow: 0px -1px 5px -1px rgba(0, 0, 0, 0.13);
    padding: 15px 15px 15px 25px;
    z-index: 1; }
    .getintio .engrator-ui-logs-search .engrator-form {
      display: flex;
      flex-direction: row;
      column-gap: 15px;
      align-items: end; }
      .getintio .engrator-ui-logs-search .engrator-form .engrator-ui-checkbox {
        margin-bottom: 10px;
        margin-top: 14px; }
      .getintio .engrator-ui-logs-search .engrator-form .engrator-form-group {
        min-width: 130px; }
      .getintio .engrator-ui-logs-search .engrator-form .link-inline {
        margin-bottom: 10px;
        margin-right: 15px; }
      .getintio .engrator-ui-logs-search .engrator-form .engrator-form-group[data-sel="jump to section"] {
        min-width: 250px; }
      .getintio .engrator-ui-logs-search .engrator-form div.download {
        margin-left: auto;
        margin-bottom: 10px; }
  .getintio .engrator-tab-bar .engrator-tab-button {
    position: relative; }
    .getintio .engrator-tab-bar .engrator-tab-button .engrator-ui-icon.engrator-ui-icon-exclamation-triangle {
      color: #ef8a33;
      margin-right: 10px; }
      .getintio .engrator-tab-bar .engrator-tab-button .engrator-ui-icon.engrator-ui-icon-exclamation-triangle svg {
        width: 13px;
        height: 13px; }
  .getintio .engrator-tab-bar .engrator-tab-button a,
  .getintio .engrator-tab-bar .engrator-tab-button button {
    text-decoration: none;
    padding: 10px;
    display: block;
    min-width: 100px;
    text-align: center;
    position: relative;
    background: #fbfbfb;
    line-height: initial;
    font-size: 12px;
    font-family: 'medium';
    color: #344563;
    border-bottom: 2px solid #cfcfcf;
    border-right: 2px solid #cfcfcf;
    border-top: 2px solid #cfcfcf; }
  .getintio .engrator-tab-bar .engrator-tab-button:last-child a,
  .getintio .engrator-tab-bar .engrator-tab-button:last-child button {
    border-right: 2px solid #cfcfcf;
    border-radius: 0 4px 4px 0; }
  .getintio .engrator-tab-bar .engrator-tab-button:first-child a,
  .getintio .engrator-tab-bar .engrator-tab-button:first-child button {
    border-left: 2px solid #cfcfcf;
    border-radius: 4px 0 0 4px; }
  .getintio .engrator-tab-bar .engrator-tab-button a:hover,
  .getintio .engrator-tab-bar .engrator-tab-button button:hover,
  .getintio .engrator-tab-bar .engrator-tab-button.selected a,
  .getintio .engrator-tab-bar .engrator-tab-button.selected button {
    background: #ddecff; }
  .getintio .engrator-ui-checkbox input {
    margin-right: 15px;
    float: left; }
  .getintio .engrator-ui-checkbox label {
    cursor: pointer;
    font-family: 'regular';
    margin-top: 0;
    font-size: 14px; }
  .getintio .engrator-ui-checkbox:after {
    content: " ";
    clear: both;
    display: block; }
  .getintio .ui-context-menu {
    position: relative; }
    .getintio .ui-context-menu .dots {
      line-height: 0;
      padding: 7px 10px;
      font-family: 'medium';
      box-sizing: border-box;
      border: 2px solid transparent; }
      .getintio .ui-context-menu .dots:hover {
        cursor: pointer;
        background: #fff;
        border: 2px solid #0050c7;
        border-radius: 5px; }
    .getintio .ui-context-menu .popup {
      position: absolute;
      z-index: 10;
      width: 150px;
      left: 33px;
      top: 0;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      background: #fff;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
      .getintio .ui-context-menu .popup ul {
        padding: 0;
        margin: 0;
        list-style: none;
        font-size: 12px; }
        .getintio .ui-context-menu .popup ul li {
          padding: 5px;
          padding-left: 15px;
          cursor: pointer;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          position: relative; }
          .getintio .ui-context-menu .popup ul li .engrator-ui-tooltip {
            position: absolute;
            right: 5px;
            top: 5px; }
          .getintio .ui-context-menu .popup ul li:last-child {
            border-bottom: none; }
          .getintio .ui-context-menu .popup ul li:hover {
            background: #f3f3f3; }
  .getintio .ui-artifacts-autocomplete {
    background: white;
    position: absolute;
    z-index: 1000;
    top: 50px;
    left: 0;
    right: 0;
    border: 1px solid #eaeaea; }
    .getintio .ui-artifacts-autocomplete p {
      padding-left: 1em; }
    .getintio .ui-artifacts-autocomplete ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .getintio .ui-artifacts-autocomplete ul li {
        display: block;
        margin: 0;
        padding: 0.5em 1em 0.5em 1.5em;
        border-top: 1px solid #eaeaea; }
        .getintio .ui-artifacts-autocomplete ul li span.bolder {
          font-family: medium; }
  .getintio .ui-read-more {
    margin-top: 5px; }
  .getintio .warning-bar {
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 99;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
    .getintio .warning-bar.danger .warning {
      background: #ca423d;
      color: #fff; }
    .getintio .warning-bar .warning {
      padding: 6px;
      border-radius: 0;
      font-family: 'bold';
      font-size: 12px; }
      .getintio .warning-bar .warning .engrator-ui-icon-exclamation-triangle {
        position: initial;
        margin-right: 10px; }
      .getintio .warning-bar .warning .engrator-ui-icon-close {
        position: absolute;
        right: 10px;
        cursor: pointer; }
  .getintio .engrator-ui-tooltip {
    position: relative;
    z-index: 3; }
    .getintio .engrator-ui-tooltip .hover-trigger {
      display: inline-block;
      position: relative; }
    .getintio .engrator-ui-tooltip .msg {
      position: absolute;
      top: -5px;
      left: 20px;
      width: max-content;
      max-width: 150px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      color: #fff;
      padding: 5px;
      font-size: 12px; }
    .getintio .engrator-ui-tooltip.position-left .msg {
      right: 20px;
      left: auto; }
    .getintio .engrator-ui-tooltip.position-right .msg {
      left: 20px;
      right: auto; }
  .getintio .connectors-jira-search-issues-step pre.final-jql {
    width: 100%;
    display: block;
    white-space: pre-line;
    background: #eee;
    padding: 1rem;
    box-sizing: border-box; }
  .getintio .artifact-builder {
    width: 100%; }
    .getintio .artifact-builder .property-mapping {
      display: flex;
      position: relative;
      flex-direction: row;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 15px;
      padding-top: 15px;
      align-items: center; }
      .getintio .artifact-builder .property-mapping div.property, .getintio .artifact-builder .property-mapping div.mapped-to {
        flex: 1; }
      .getintio .artifact-builder .property-mapping div.button-container {
        flex: 0; }
      .getintio .artifact-builder .property-mapping p.condition {
        position: absolute;
        font-size: 12px;
        bottom: 0;
        color: rgba(0, 0, 0, 0.5);
        left: 0; }
    .getintio .artifact-builder .property-mapping-creator {
      padding-bottom: 15px;
      border-bottom: 1px solid #eaeaea; }
      .getintio .artifact-builder .property-mapping-creator section.pick-property {
        margin-bottom: 15px; }
      .getintio .artifact-builder .property-mapping-creator .selection {
        padding-bottom: 15px; }
        .getintio .artifact-builder .property-mapping-creator .selection section:first-child {
          padding-right: 15px; }
        .getintio .artifact-builder .property-mapping-creator .selection section {
          flex: 1;
          position: relative; }
        .getintio .artifact-builder .property-mapping-creator .selection select[name="propertyId"] {
          margin-top: 15px; }
      .getintio .artifact-builder .property-mapping-creator .button-bottom .button-container button {
        float: right; }
      .getintio .artifact-builder .property-mapping-creator .button-bottom .button-container:after {
        display: block;
        content: "";
        clear: both; }
  .getintio .dashboard-page .create-integration-guide {
    padding-top: 50px; }
  .getintio .create-integration-guide {
    display: block;
    max-width: 900px;
    width: 100%;
    margin: auto; }
    .getintio .create-integration-guide h1 {
      color: #0050c7;
      margin-bottom: 25px;
      text-align: center; }
    .getintio .create-integration-guide p.help-needed {
      text-align: center;
      display: block;
      margin: 15px auto auto; }
    .getintio .create-integration-guide .create-link {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 20px; }
      .getintio .create-integration-guide .create-link a {
        background: #0050c7;
        border-radius: 4px;
        color: #fff;
        height: 37px;
        line-height: 37px;
        padding: 0 15px;
        margin: auto;
        display: block;
        max-width: 200px;
        text-align: center; }
    .getintio .create-integration-guide .engrator-buttons-bar {
      display: block;
      margin-top: 50px; }
  .getintio .dashboard-page {
    padding-top: 0; }
    .getintio .dashboard-page .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 1200px;
      margin: auto; }
      .getintio .dashboard-page .content .engrator-ui-dashboard-tile .engrator-ui-tile {
        position: relative;
        height: 240px; }
        .getintio .dashboard-page .content .engrator-ui-dashboard-tile .engrator-ui-tile .footer {
          left: 1rem;
          right: 1rem;
          bottom: 1rem; }
        .getintio .dashboard-page .content .engrator-ui-dashboard-tile .engrator-ui-tile a {
          font-size: 12px; }
      .getintio .dashboard-page .content .engrator-ui-dashboard-tile .content {
        margin: 0;
        text-align: center;
        justify-content: center; }
      .getintio .dashboard-page .content .engrator-ui-dashboard-tile .list-chart {
        width: 100%; }
        .getintio .dashboard-page .content .engrator-ui-dashboard-tile .list-chart .entry {
          border-bottom: 1px solid #eaeaea;
          padding: 5px 0;
          padding-right: 80px;
          position: relative;
          text-align: left;
          font-size: 12px; }
          .getintio .dashboard-page .content .engrator-ui-dashboard-tile .list-chart .entry .engrator-nav-link {
            position: absolute;
            top: 50%;
            margin-top: -10px;
            right: 0; }
        .getintio .dashboard-page .content .engrator-ui-dashboard-tile .list-chart .engrator-ui-message.error {
          font-size: 12px; }
  @media only screen and (max-width: 1200px) {
    .getintio .dashboard-page .content .engrator-ui-dashboard-tile {
      width: 100%; } }
  .getintio nav {
    display: block;
    position: fixed;
    width: 60px;
    top: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    border-right: 1px solid #e1e1e1;
    z-index: 10000;
    font-family: 'bold', Arial, Helvetica, sans-serif;
    height: 100%;
    justify-content: space-between;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
  .getintio nav ul.nav-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; }
  .getintio nav ul.nav-menu.right {
    position: absolute;
    bottom: 10px; }
  .getintio nav ul.nav-menu > li {
    margin: 0;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    border-left: 3px solid transparent;
    position: relative; }
    .getintio nav ul.nav-menu > li:hover .tooltip {
      opacity: 1; }
    .getintio nav ul.nav-menu > li .tooltip {
      background: #434f5f;
      color: #fff;
      font-size: 12px;
      padding: 3px 5px;
      opacity: 0;
      position: absolute;
      border-radius: 4px;
      left: 65px;
      pointer-events: none;
      top: 13px;
      transition: 0.2s; }
  .getintio nav ul.nav-menu > li > a {
    text-decoration: none;
    display: block;
    position: relative;
    color: #344563;
    font-size: 15px;
    height: 100%;
    text-align: center;
    width: 100%;
    display: block;
    position: relative; }
    .getintio nav ul.nav-menu > li > a .engrator-ui-icon {
      display: block;
      left: 18px;
      top: 17px;
      position: absolute; }
  .getintio nav ul.nav-menu > li:not(.logo) {
    cursor: pointer; }
  .getintio nav ul.nav-menu > li.logo {
    border-bottom: 1px solid #e1e1e1; }
  .getintio nav ul.nav-menu > li.logo div.logotype img {
    margin-left: 8px;
    top: 12px; }
  .getintio nav ul.nav-menu li.selected > a,
  .getintio nav ul.nav-menu li:hover > a {
    color: #0050c7; }
  .getintio nav ul.nav-menu > li:not(.logo):hover,
  .getintio nav ul.nav-menu > li.selected {
    border-left: 3px solid #0050c7;
    background: #f3f3f3; }
  .getintio nav:after {
    content: "";
    position: absolute;
    left: 0px;
    right: 0px;
    top: 100%;
    height: 4px; }
  .getintio nav ul.nav-menu.right > li {
    border-left: 1px solid #eaeaea; }
  .getintio nav ul.nav-menu.right > li.lastly-synced-items-box {
    border-left: none;
    cursor: initial; }
  .getintio .lastly-synced-items {
    padding: 0 15px;
    font-size: 12px;
    padding-top: 12px; }
    .getintio .lastly-synced-items label {
      display: block;
      padding-bottom: 3px; }
    .getintio .lastly-synced-items .item-box {
      opacity: 0; }
      .getintio .lastly-synced-items .item-box.visible {
        opacity: 1;
        transition: all 250ms linear 2s; }
      .getintio .lastly-synced-items .item-box.hidden {
        opacity: 0;
        pointer-events: none;
        transition: all 250ms linear 2s; }
  .getintio .loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    text-align: center;
    padding-top: 200px; }
    .getintio .loading-overlay > div {
      width: 600px;
      top: 50%;
      position: absolute;
      left: 50%;
      margin: -50px auto auto -300px; }
      .getintio .loading-overlay > div p {
        font-family: 'medium';
        margin: 0 0 20px 0; }
  .getintio .horizontal-sub-menu {
    position: fixed;
    top: 0;
    left: 61px;
    bottom: 0;
    width: 160px;
    background: #ededf0;
    font-size: 12px;
    padding: 0px 13px;
    box-sizing: border-box;
    font-family: 'medium';
    border-right: 1px solid #dedede;
    z-index: 1; }
    .getintio .horizontal-sub-menu .caption {
      box-sizing: border-box;
      font-size: 14px;
      margin: 20px 0px 20px 0px;
      padding: 0; }
    .getintio .horizontal-sub-menu ul {
      margin: 0;
      padding: 0; }
    .getintio .horizontal-sub-menu li {
      list-style: none;
      margin: 0;
      margin-bottom: 15px;
      position: relative; }
      .getintio .horizontal-sub-menu li:hover, .getintio .horizontal-sub-menu li.selected {
        background: #fff;
        border-radius: 4px; }
      .getintio .horizontal-sub-menu li.with-img a {
        box-sizing: border-box;
        padding-left: 30px; }
      .getintio .horizontal-sub-menu li a {
        display: block;
        padding: 8px 5px;
        font-size: 12px;
        color: #434f5f;
        width: 100%; }
        .getintio .horizontal-sub-menu li a .engrator-ui-icon {
          left: 5px;
          position: absolute; }
  .getintio .top-horizontal-menu {
    position: fixed;
    top: 0;
    right: 0;
    left: 210px;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    box-sizing: border-box;
    font-size: 12px;
    padding-right: 35px;
    background: #ededf0;
    z-index: 1;
    border-bottom: 1px solid #dedede; }
    .getintio .top-horizontal-menu.no-menu {
      left: 50px; }
    .getintio .top-horizontal-menu .item {
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 10px; }
      .getintio .top-horizontal-menu .item .btn-bg {
        background: #fff;
        border-radius: 4px;
        padding: 4px;
        box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
        cursor: pointer; }
      .getintio .top-horizontal-menu .item.steps {
        flex-grow: 1;
        padding-left: 30px; }
      .getintio .top-horizontal-menu .item ul.breadcrumbs {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        flex-direction: row; }
        .getintio .top-horizontal-menu .item ul.breadcrumbs li {
          margin: 0;
          padding-right: 10px; }
          .getintio .top-horizontal-menu .item ul.breadcrumbs li:last-child {
            display: none; }
    .getintio .top-horizontal-menu .item-synced-with {
      font-family: 'medium';
      padding-right: 15px; }
      .getintio .top-horizontal-menu .item-synced-with svg {
        margin: 0 3px; }
  .getintio .account-dropdown, .getintio .help-dropdown {
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    border-radius: 4px;
    border: 1px solid #eaeaea;
    background-color: #fff;
    padding: 10px 15px;
    max-width: 250px;
    width: 100%;
    z-index: 1000;
    cursor: initial;
    position: fixed;
    left: 60px;
    bottom: 80px; }
    .getintio .account-dropdown .close, .getintio .help-dropdown .close {
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      width: 16px; }
    .getintio .account-dropdown h3, .getintio .help-dropdown h3 {
      margin: 0 0 15px 0;
      padding: 15px 0;
      border-bottom: 1px solid #eaeaea; }
    .getintio .account-dropdown ul, .getintio .help-dropdown ul {
      list-style: none;
      padding: 0;
      margin: 0;
      cursor: initial; }
      .getintio .account-dropdown ul li, .getintio .help-dropdown ul li {
        cursor: pointer;
        border-radius: 4px; }
        .getintio .account-dropdown ul li:hover, .getintio .help-dropdown ul li:hover {
          background-color: rgba(123, 123, 123, 0.05);
          color: #0050c7; }
        .getintio .account-dropdown ul li a, .getintio .help-dropdown ul li a {
          width: 100%;
          display: block;
          color: inherit;
          text-decoration: none;
          color: #434f5f;
          padding: 10px 10px; }
          .getintio .account-dropdown ul li a .engrator-ui-icon, .getintio .help-dropdown ul li a .engrator-ui-icon {
            margin-right: 10px; }
    .getintio .account-dropdown .version, .getintio .help-dropdown .version {
      text-align: center;
      color: #434f5f;
      margin-top: 10px;
      font-size: 12px;
      border-top: 1px solid #eaeaea;
      padding-top: 15px;
      font-family: 'regular-paragraph'; }
      .getintio .account-dropdown .version a, .getintio .help-dropdown .version a {
        font-size: 12px; }
  .getintio .reporting-filters {
    margin-bottom: 3rem; }
    .getintio .reporting-filters > .engrator-ui-tile {
      display: flex;
      position: relative; }
      .getintio .reporting-filters > .engrator-ui-tile > .button-container {
        position: absolute;
        right: 1rem; }
  .getintio .flow-mode {
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'medium';
    background: #fffae6; }
    .getintio .flow-mode.create {
      background: #eaffee; }
    .getintio .flow-mode.run-mode {
      background: #f3f3f3; }
    .getintio .flow-mode.migration, .getintio .flow-mode.archiving {
      background: #fffae6; }
  .getintio .flow-status {
    display: flex;
    align-items: end;
    column-gap: 10px; }
  .getintio .subitem-tag {
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 10px;
    text-transform: uppercase;
    font-family: 'medium';
    background: #f3f3f3;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    column-gap: 3px; }
    .getintio .subitem-tag.warn {
      background: #fffae6; }
      .getintio .subitem-tag.warn .engrator-ui-icon {
        color: #ef8a33; }
  .getintio .subitems-data {
    font-size: 12px;
    list-style: none;
    margin: 0;
    padding: 0; }
  .getintio .search-filter {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    min-width: 105px;
    height: 35px;
    position: relative;
    margin-right: 15px; }
    .getintio .search-filter label.title {
      color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      display: block;
      font-family: 'medium';
      line-height: 35px;
      padding-left: 10px;
      width: max-content;
      min-width: 90px;
      padding-right: 25px; }
      .getintio .search-filter label.title.selected {
        color: initial; }
      .getintio .search-filter label.title:hover {
        background: #f3f3f3; }
    .getintio .search-filter.filter input {
      height: 37px; }
    .getintio .search-filter.number-range > div {
      display: flex;
      max-width: 10rem; }
      .getintio .search-filter.number-range > div > div {
        margin-right: 0.5rem;
        flex: 50px;
        box-sizing: border-box; }
    .getintio .search-filter .popup {
      z-index: 1;
      background-color: #fff;
      box-shadow: -1px 1px 11px -1px rgba(0, 0, 0, 0.33);
      width: 12rem;
      position: absolute;
      padding: 15px 15px 10px 15px;
      top: 45px;
      flex-wrap: wrap; }
      .getintio .search-filter .popup .break {
        flex-basis: 100%; }
      .getintio .search-filter .popup .engrator-buttons-bar {
        border-top: 1px solid #eaeaea; }
        .getintio .search-filter .popup .engrator-buttons-bar button:first-child {
          margin-right: 15px; }
      .getintio .search-filter .popup .engrator-buttons-bar .col.right {
        margin-left: 0; }
      .getintio .search-filter .popup ul {
        padding: 0;
        margin: 0; }
      .getintio .search-filter .popup li {
        list-style: none;
        margin: 0;
        line-height: 35px;
        height: 35px;
        position: relative;
        padding-left: 32px; }
        .getintio .search-filter .popup li > .engrator-ui-icon {
          line-height: initial;
          position: absolute;
          top: 9.5px;
          left: 6px; }
      .getintio .search-filter .popup li:hover {
        background-color: #f4f4f7;
        cursor: pointer; }
      .getintio .search-filter .popup li.selected {
        font-weight: bold; }
    .getintio .search-filter.dropdown {
      position: relative; }
      .getintio .search-filter.dropdown .engrator-ui-icon-chevrondown {
        position: absolute;
        top: 9px;
        pointer-events: none;
        line-height: initial;
        right: 5px;
        opacity: 0.6; }
      .getintio .search-filter.dropdown .placeholder {
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        line-height: 2rem; }
      .getintio .search-filter.dropdown .selected-values {
        color: #434f5f;
        font-weight: bold;
        cursor: pointer;
        line-height: 2rem; }
  .getintio .artifact-version-preview-link {
    display: inline-block; }
    .getintio .artifact-version-preview-link .item-id {
      font-family: 'medium';
      color: #0050c7;
      cursor: pointer;
      text-decoration: underline;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 150px;
      display: inline-block; }
    .getintio .artifact-version-preview-link .preview {
      opacity: 0;
      margin-left: 3px; }
    .getintio .artifact-version-preview-link:hover .preview {
      opacity: 1;
      cursor: pointer; }
  .getintio .artifact-version-data-preview .inner button {
    margin: auto; }
  .getintio .artifact-version-data-preview table.items-data {
    width: 100%;
    margin: 25px auto;
    border-bottom: 1px solid #eaeaea; }
    .getintio .artifact-version-data-preview table.items-data tr {
      display: block;
      border-top: 1px solid #eaeaea; }
      .getintio .artifact-version-data-preview table.items-data tr td {
        padding: 5px !important;
        border-bottom: none !important; }
      .getintio .artifact-version-data-preview table.items-data tr td:first-child {
        padding: 5px;
        border-right: 1px solid #eaeaea;
        border-bottom: none;
        color: #434f5f;
        font-family: 'medium';
        width: 150px;
        text-align: right;
        vertical-align: top; }
  .getintio .artifact-version-data-preview table.subitems-data {
    width: 100%;
    margin: 25px auto;
    border-bottom: 1px solid #eaeaea; }
    .getintio .artifact-version-data-preview table.subitems-data tr {
      display: block;
      border-top: 1px solid #eaeaea; }
      .getintio .artifact-version-data-preview table.subitems-data tr td {
        padding: 5px !important;
        border-bottom: none !important;
        border-right: 1px solid #eaeaea; }
      .getintio .artifact-version-data-preview table.subitems-data tr td:first-child {
        color: #434f5f;
        font-family: 'bold';
        width: 150px;
        text-align: right;
        vertical-align: top; }
  .getintio .import-popup .inner button {
    margin: auto; }
  .getintio .content > .engrator-page.flow-details-page {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 160px; }
    .getintio .content > .engrator-page.flow-details-page p.left-padding {
      padding-left: 25px; }
    .getintio .content > .engrator-page.flow-details-page .engrator-ui-message.error {
      text-align: center; }
    .getintio .content > .engrator-page.flow-details-page .steps-list-background {
      padding-left: 0; }
      .getintio .content > .engrator-page.flow-details-page .steps-list-background.two-columns .left {
        max-width: 550px;
        width: 100%;
        padding-right: 0; }
    .getintio .content > .engrator-page.flow-details-page .page-header {
      height: 100%;
      display: flex;
      background: #fff;
      padding: 20px;
      box-sizing: border-box;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      flex-direction: column;
      font-size: 12px; }
      .getintio .content > .engrator-page.flow-details-page .page-header .subitems-data {
        margin-top: 15px; }
      .getintio .content > .engrator-page.flow-details-page .page-header h3 {
        color: #0050c7;
        font-size: 21px;
        padding-bottom: 15px;
        margin-bottom: 15px; }
      .getintio .content > .engrator-page.flow-details-page .page-header h1 .engrator-ui-execution-time {
        padding-right: 10px;
        font-size: 12px; }
      .getintio .content > .engrator-page.flow-details-page .page-header > div {
        padding-right: 25px;
        min-width: 150px; }
      .getintio .content > .engrator-page.flow-details-page .page-header > .label-value {
        padding: 10px;
        border-bottom: 1px solid #eaeaea; }
        .getintio .content > .engrator-page.flow-details-page .page-header > .label-value.time .sync-id {
          float: left; }
        .getintio .content > .engrator-page.flow-details-page .page-header > .label-value.time label {
          float: left; }
        .getintio .content > .engrator-page.flow-details-page .page-header > .label-value.time > span {
          float: left; }
        .getintio .content > .engrator-page.flow-details-page .page-header > .label-value.time .engrator-ui-execution-time {
          float: left;
          margin-left: 20px; }
          .getintio .content > .engrator-page.flow-details-page .page-header > .label-value.time .engrator-ui-execution-time:after {
            content: " ";
            clear: both; }
        .getintio .content > .engrator-page.flow-details-page .page-header > .label-value > label {
          width: 100px;
          display: inline-block; }
        .getintio .content > .engrator-page.flow-details-page .page-header > .label-value a {
          font-size: 14px; }
      .getintio .content > .engrator-page.flow-details-page .page-header .basic-info label {
        display: inline-block;
        min-width: 50px;
        margin-bottom: 10px; }
      .getintio .content > .engrator-page.flow-details-page .page-header .failure-reason p {
        font-style: initial;
        font-weight: initial;
        font-family: 'regular';
        margin-top: 3px; }
      .getintio .content > .engrator-page.flow-details-page .page-header .failure-reason .engrator-ui-message.error {
        font-size: 12px; }
      .getintio .content > .engrator-page.flow-details-page .page-header .flow-error {
        min-width: 50%; }
    .getintio .content > .engrator-page.flow-details-page .nav-bar {
      margin-bottom: 25px;
      margin-right: 25px; }
    .getintio .content > .engrator-page.flow-details-page .SnippetsList .snippet-container.Success .snippet {
      border-color: #4dad5d;
      border-width: 2px; }
    .getintio .content > .engrator-page.flow-details-page .SnippetsList .snippet-container .snippet:hover,
    .getintio .content > .engrator-page.flow-details-page .SnippetsList .snippet-container.selected .snippet {
      border-width: 1px;
      background-color: #f3f3f3; }
    .getintio .content > .engrator-page.flow-details-page .SnippetsList .snippet-container .stats {
      text-align: center;
      padding-top: 15px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5); }
      .getintio .content > .engrator-page.flow-details-page .SnippetsList .snippet-container .stats .artifacts {
        padding-right: 15px; }
        .getintio .content > .engrator-page.flow-details-page .SnippetsList .snippet-container .stats .artifacts .engrator-ui-icon-artifact {
          padding-right: 5px; }
    .getintio .content > .engrator-page.flow-details-page .RightPanel {
      width: 90%; }
      .getintio .content > .engrator-page.flow-details-page .RightPanel .tab.logs {
        position: relative;
        height: 100%; }
        .getintio .content > .engrator-page.flow-details-page .RightPanel .tab.logs pre {
          position: absolute;
          overflow: auto; }
    .getintio .content > .engrator-page.flow-details-page .run-results-logs {
      margin-top: 25px; }
    .getintio .content > .engrator-page.flow-details-page h3.subheader {
      margin: 25px 0; }
    .getintio .content > .engrator-page.flow-details-page .SceneContainer {
      top: 300px;
      padding-top: 0px;
      position: initial;
      padding-bottom: 15px;
      border-top: none; }
  .getintio .onboarding-progress {
    position: fixed;
    top: 100px;
    right: 20px;
    bottom: 30px;
    z-index: 10; }
    .getintio .onboarding-progress .progress-btn {
      background: #6554c0;
      color: #fff;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 8px 16px -4px;
      border-radius: 25px;
      width: 160px;
      padding-bottom: 20px;
      padding-top: 10px;
      text-align: center;
      margin-right: 10px;
      font-size: 14px;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      right: 0;
      cursor: pointer; }
      .getintio .onboarding-progress .progress-btn.no-progress {
        font-size: 17px;
        padding-top: 15px;
        padding-bottom: 15px; }
      .getintio .onboarding-progress .progress-btn .progress-bar {
        background: #fff;
        width: 120px;
        height: 5px;
        border-radius: 2px;
        position: absolute;
        bottom: 10px;
        left: 20px;
        right: 20px; }
      .getintio .onboarding-progress .progress-btn:hover {
        background: #2b1c7c; }
    .getintio .onboarding-progress .progress-box {
      top: 0px;
      display: none;
      right: 0px;
      bottom: 0px;
      z-index: 11;
      width: 550px;
      position: absolute;
      background: #f4f5f7;
      box-sizing: border-box;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 8px 16px -4px; }
      .getintio .onboarding-progress .progress-box.visible {
        display: block; }
      .getintio .onboarding-progress .progress-box .close-btn {
        position: absolute;
        right: 20px;
        cursor: pointer; }
      .getintio .onboarding-progress .progress-box h1 {
        background: #6554c0;
        color: #fff;
        padding: 20px;
        text-align: center;
        position: relative; }
      .getintio .onboarding-progress .progress-box > .button-container.link-inline button {
        margin: auto; }
      .getintio .onboarding-progress .progress-box .steps .step {
        background: #fff;
        box-sizing: border-box;
        margin: 20px;
        border-radius: 4px;
        padding: 20px;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; }
        .getintio .onboarding-progress .progress-box .steps .step h3 {
          font-size: 17px;
          font-family: 'bold';
          margin: 0;
          padding: 0; }
        .getintio .onboarding-progress .progress-box .steps .step p {
          margin: 0;
          padding: 0;
          margin-top: 5px; }
          .getintio .onboarding-progress .progress-box .steps .step p.links {
            margin-top: 10px; }
  .getintio .integration-threads-page {
    padding-top: 25px; }
    .getintio .integration-threads-page .threads-list {
      display: flex;
      max-width: 100%;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap; }
  .getintio .integration-thread-tile {
    max-width: 550px;
    min-height: 250px;
    display: block;
    margin: 15px;
    margin-bottom: 25px;
    font-size: 14px;
    flex-grow: 1;
    width: 50%; }
    .getintio .integration-thread-tile .engrator-ui-tile {
      display: flex;
      flex-direction: row; }
      .getintio .integration-thread-tile .engrator-ui-tile > div {
        min-width: 170px; }
    .getintio .integration-thread-tile.inactive {
      opacity: 0.6; }
    .getintio .integration-thread-tile p.info {
      margin-top: 20px;
      margin-bottom: 0;
      text-transform: uppercase;
      font-family: 'bold';
      text-align: center; }
    .getintio .integration-thread-tile .execution-time {
      height: 2rem; }
    .getintio .integration-thread-tile .thread-id {
      margin-top: 0;
      font-size: 38px;
      text-align: center; }
      .getintio .integration-thread-tile .thread-id span {
        font-size: 12px;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.5); }
    .getintio .integration-thread-tile h4 {
      margin: 15px 0; }
    .getintio .integration-thread-tile .run-info .engrator-ui-execution-time {
      padding-right: 10px; }
  .getintio .analysis-nav-bar {
    margin-bottom: 2rem; }
  .getintio table.analysis-errors td.td-details,
  .getintio table.analysis-errors td.td-message {
    color: #ca423d;
    word-break: break-word; }
  .getintio .value-mappings-group {
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
    position: relative;
    display: flex;
    column-gap: 15px; }
    .getintio .value-mappings-group .hidden {
      display: none; }
    .getintio .value-mappings-group .left, .getintio .value-mappings-group .right {
      flex: 1;
      font-family: 'medium';
      position: relative;
      background: #f3f3f3;
      border-radius: 4px;
      box-sizing: border-box; }
      .getintio .value-mappings-group .left.center, .getintio .value-mappings-group .right.center {
        text-align: center; }
    .getintio .value-mappings-group .left > div,
    .getintio .value-mappings-group .right > div {
      position: relative;
      padding: 10px 0;
      padding-left: 15px; }
      .getintio .value-mappings-group .left > div span.value-id,
      .getintio .value-mappings-group .left > div span.condition,
      .getintio .value-mappings-group .right > div span.value-id,
      .getintio .value-mappings-group .right > div span.condition {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.5); }
      .getintio .value-mappings-group .left > div span.condition,
      .getintio .value-mappings-group .right > div span.condition {
        padding-left: 3px; }
      .getintio .value-mappings-group .left > div .delete,
      .getintio .value-mappings-group .right > div .delete {
        top: 6px;
        right: 13px;
        position: absolute;
        cursor: pointer;
        line-height: initial; }
      .getintio .value-mappings-group .left > div .default,
      .getintio .value-mappings-group .right > div .default {
        position: absolute;
        right: 40px;
        top: 4px; }
      .getintio .value-mappings-group .left > div span.is-default,
      .getintio .value-mappings-group .right > div span.is-default {
        color: rgba(0, 0, 0, 0.5);
        margin-left: 15px; }
    .getintio .value-mappings-group .cog {
      display: initial;
      left: initial;
      right: 0px;
      top: 0px; }
    .getintio .value-mappings-group:hover .hidden {
      display: initial; }
  .getintio .default-mappings {
    border-bottom: 1px solid #eaeaea;
    padding: 15px 0; }
    .getintio .default-mappings .header {
      margin-bottom: 10px; }
    .getintio .default-mappings .flex-equal:first-child {
      box-sizing: border-box;
      padding-right: 15px; }
  .getintio .edit-default-mapping label {
    margin: 5px 0;
    display: block; }
  .getintio .edit-default-mapping > .button-container {
    margin-top: 15px; }
    .getintio .edit-default-mapping > .button-container button {
      float: right; }
  .getintio .migration-settings {
    overflow: auto;
    max-width: 800px;
    margin: auto; }
    .getintio .migration-settings h1 {
      margin-bottom: 15px; }
    .getintio .migration-settings .box.less-padding {
      margin: 20px auto;
      text-align: left; }
      .getintio .migration-settings .box.less-padding .engrator-ui-message.error {
        margin-top: 15px;
        height: 25px; }
  .getintio .integrations-list-context-menu {
    max-width: 30px;
    float: right;
    margin-right: 30px; }
    .getintio .integrations-list-context-menu .dots {
      padding-left: 5px; }
    .getintio .integrations-list-context-menu .ui-context-menu .popup {
      left: auto;
      right: 30px; }
  .getintio .duplicate-smartint .box {
    text-align: left; }
    .getintio .duplicate-smartint .box .triggers > div {
      border: 1px solid #eaeaea;
      margin: 0 15px;
      padding: 15px; }
      .getintio .duplicate-smartint .box .triggers > div .app-logotype {
        line-height: 30px;
        height: 30px; }
        .getintio .duplicate-smartint .box .triggers > div .app-logotype img {
          top: 0px; }
  .getintio .duplicate-smartint.persistence-loader .box.wide {
    max-width: 55rem;
    width: 90%; }
    .getintio .duplicate-smartint.persistence-loader .box.wide h3 {
      text-align: left;
      margin-top: 25px;
      margin-bottom: 15px; }
  .getintio .item-filtering h2 {
    margin-top: 20px;
    color: #0050c7;
    font-family: 'medium'; }
  .getintio .add-entry-row {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 15px; }
    .getintio .add-entry-row .controls {
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      gap: 15px; }
      .getintio .add-entry-row .controls > div {
        box-sizing: border-box;
        flex: 1;
        max-width: 250px; }
        .getintio .add-entry-row .controls > div.button-container button {
          margin-top: 34px; }
  .getintio .display-entry-row {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 0;
    padding: 5px; }
    .getintio .display-entry-row:hover {
      background: #f3f3f3; }
    .getintio .display-entry-row > div, .getintio .display-entry-row > ul {
      flex: 1;
      max-width: 250px;
      text-align: left; }
      .getintio .display-entry-row > div .engrator-ui-icon-trash, .getintio .display-entry-row > ul .engrator-ui-icon-trash {
        cursor: pointer; }
    .getintio .display-entry-row .last-one {
      max-width: initial; }
    .getintio .display-entry-row ul {
      margin: 0;
      padding: 0;
      list-style: none; }
  .getintio .relationships-configuration .add-entry-row {
    margin-bottom: 15px; }
  .getintio .relationships-configuration h2 {
    color: #0050c7;
    font-family: 'medium';
    margin-bottom: 10px;
    margin-top: 10px; }
  .getintio .configure-plugin {
    display: flex;
    flex-direction: row;
    column-gap: 15px; }
    .getintio .configure-plugin > div {
      flex: 1;
      width: 300px; }
    .getintio .configure-plugin .debug-result {
      font-size: 14px;
      padding: 15px;
      background: #f3f3f3;
      border: 1px solid #eaeaea;
      border-radius: 4px;
      max-width: -webkit-fill-available;
      overflow: auto; }
  .getintio .integration-plugins form .engrator-buttons-bar {
    margin-top: 15px; }
  .getintio .integration-plugins .plugins-list {
    display: flex;
    flex-direction: row;
    column-gap: 20px; }
  .getintio .integration-plugins .plugin-cmp {
    border: 1px solid #eaeaea;
    display: flex;
    max-width: 300px;
    padding: 15px;
    column-gap: 15px; }
    .getintio .integration-plugins .plugin-cmp div.name {
      flex: 1;
      font-family: 'bold'; }
      .getintio .integration-plugins .plugin-cmp div.name p {
        font-family: 'regular-paragraph';
        font-size: 12px; }
    .getintio .integration-plugins .plugin-cmp .secondary button {
      width: 75px; }
    .getintio .integration-plugins .plugin-cmp .secondary:first-child {
      margin-bottom: 10px; }
    .getintio .integration-plugins .plugin-cmp .engrator-ui-icon-settings {
      position: initial;
      width: initial; }
  .getintio .system-nav-bar {
    margin-bottom: 2rem; }
    .getintio .system-nav-bar > .engrator-tab-bar {
      flex: 1; }
  .getintio .upgrade-version-progress ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .getintio .upgrade-version-progress ul li {
      padding: 0;
      margin: 15px 0;
      color: rgba(0, 0, 0, 0.5); }
      .getintio .upgrade-version-progress ul li .engrator-ui-icon {
        display: none; }
      .getintio .upgrade-version-progress ul li.current {
        font-family: 'medium'; }
      .getintio .upgrade-version-progress ul li.done {
        font-family: 'medium';
        color: #434f5f; }
        .getintio .upgrade-version-progress ul li.done .engrator-ui-icon {
          display: inline-block;
          color: #4dad5d; }
  .getintio table.account-access span.active {
    color: #4dad5d; }
  .getintio .notifications-list-page .tabbar {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row; }
    .getintio .notifications-list-page .tabbar .button-container {
      margin-right: 15px; }
    .getintio .notifications-list-page .tabbar .engrator-tab-bar {
      flex: 1; }
  .getintio .pipelines-page .connections-tabbar {
    display: flex;
    align-items: start;
    column-gap: 15px; }
    .getintio .pipelines-page .connections-tabbar > .engrator-ui-message {
      padding: 10px;
      flex: 1;
      margin-top: 0; }
  .getintio .pipelines-page .engrator-ui-message.warning {
    margin-bottom: 15px; }
  .getintio .pipelines-page .integrations-tiles {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .getintio .pipelines-page .integrations-tiles .integration-tile {
      width: 100%;
      max-width: 30%;
      box-sizing: border-box;
      padding: 15px;
      background-color: #fff;
      box-shadow: 0px -1px 5px -1px rgba(0, 0, 0, 0.13); }
  .getintio .pipelines-page .tabbar {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center; }
    .getintio .pipelines-page .tabbar *:last-child .button-container {
      margin-right: 0; }
    .getintio .pipelines-page .tabbar .button-container {
      margin-right: 15px; }
      .getintio .pipelines-page .tabbar .button-container.secondary {
        margin-left: auto; }
    .getintio .pipelines-page .tabbar .engrator-tab-bar {
      flex: 1; }
  .getintio .pipelines-page .engrator-table th:last-child {
    text-align: right;
    padding-right: 30px; }
  .getintio .pipelines-page .engrator-table td .warning-flag {
    background: transparent; }
  .getintio .pipelines-page .status {
    display: flex;
    align-items: center; }
    .getintio .pipelines-page .status a {
      overflow: hidden;
      max-width: 50px;
      width: 100%;
      display: block;
      text-overflow: ellipsis; }
  .getintio .pipelines-page .status > span.engrator-ui-execution-time {
    padding-right: 5px; }
  .getintio .pipelines-page .connected-apps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left; }
    .getintio .pipelines-page .connected-apps .engrator-ui-icon-getint img {
      height: 14px;
      width: 17px;
      margin-top: 3px;
      opacity: 0.7; }
    .getintio .pipelines-page .connected-apps .engrator-ui-icon-getint:last-child {
      display: none; }
  .getintio .pipelines-page .groups-and-table {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start; }
    .getintio .pipelines-page .groups-and-table .groups-list {
      border-radius: 4px;
      background: #fff;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      margin-right: 20px;
      border: 1px solid #eaeaea;
      min-width: 250px; }
      .getintio .pipelines-page .groups-and-table .groups-list h4 {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 14px;
        font-family: 'medium';
        border-bottom: 1px solid #eaeaea;
        line-height: 49px;
        padding-left: 20px; }
      .getintio .pipelines-page .groups-and-table .groups-list ul {
        list-style: none;
        padding: 0;
        margin: 0;
        padding: 15px 20px; }
        .getintio .pipelines-page .groups-and-table .groups-list ul li {
          padding: 3px 10px;
          margin: 5px 0;
          font-size: 14px; }
          .getintio .pipelines-page .groups-and-table .groups-list ul li:not(:first-child) {
            padding-left: 20px; }
          .getintio .pipelines-page .groups-and-table .groups-list ul li:hover {
            background: #f3f3f3;
            border-radius: 4px;
            cursor: pointer; }
          .getintio .pipelines-page .groups-and-table .groups-list ul li.selected {
            background: #f3f3f3;
            border-radius: 4px; }
  .getintio .pipelines-page .bulk-edit-integrations {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 990;
    text-align: center;
    background: #fff;
    box-shadow: 0px -7px 5px -1px rgba(0, 0, 0, 0.13);
    padding: 0 15px 20px 20px; }
    .getintio .pipelines-page .bulk-edit-integrations > .engrator-buttons-bar {
      display: block; }
      .getintio .pipelines-page .bulk-edit-integrations > .engrator-buttons-bar .col.left {
        text-align: center; }
        .getintio .pipelines-page .bulk-edit-integrations > .engrator-buttons-bar .col.left .button-container {
          float: initial;
          margin-right: 20px;
          margin-left: 20px;
          display: inline-block; }
    .getintio .pipelines-page .bulk-edit-integrations span.operation-name {
      color: #ca423d; }
  .getintio .centralized {
    margin: auto;
    margin-top: 20px;
    display: block;
    text-align: center; }
  .getintio .smartints-wizard {
    z-index: 10000; }
    .getintio .smartints-wizard > .content {
      background-color: #fff;
      max-width: 80rem;
      width: 50%;
      margin: auto;
      padding: 15px;
      margin-top: 25px; }
      .getintio .smartints-wizard > .content .header {
        text-align: center;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 20px; }
        .getintio .smartints-wizard > .content .header p {
          width: 70%;
          display: block;
          margin: auto;
          margin-top: 15px;
          color: #434f5f; }
      .getintio .smartints-wizard > .content > .engrator-buttons-bar {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #eaeaea; }
    .getintio .smartints-wizard .steps-progress {
      margin: 0 0 25px 0;
      font-size: 12px; }
      .getintio .smartints-wizard .steps-progress ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex; }
        .getintio .smartints-wizard .steps-progress ul li {
          flex: 1;
          text-align: center; }
          .getintio .smartints-wizard .steps-progress ul li.selected {
            font-family: 'bold';
            color: #0050c7; }
    .getintio .smartints-wizard .step {
      position: relative; }
      .getintio .smartints-wizard .step .app-logotype {
        display: block;
        margin: auto;
        max-width: 10rem; }
    .getintio .smartints-wizard .select-apps-step .lists {
      display: flex;
      flex-direction: row; }
      .getintio .smartints-wizard .select-apps-step .lists .software-list {
        flex: 1;
        position: relative;
        padding: 15px; }
        .getintio .smartints-wizard .select-apps-step .lists .software-list h2 {
          display: none; }
        .getintio .smartints-wizard .select-apps-step .lists .software-list > div:last-child {
          border-bottom: none; }
    .getintio .smartints-wizard .select-connections-step .connection-dropdown > h2 {
      display: none; }
    .getintio .smartints-wizard .select-connections-step .engrator-right-panel-content {
      flex: 1;
      padding: 0 15px;
      height: auto; }
    .getintio .smartints-wizard .select-connections-step .engrator-right-panel-content > h1 {
      display: none; }
    .getintio .smartints-wizard .select-connections-step .engrator-right-panel-content div.inner-content {
      padding: 0;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      right: auto; }
    .getintio .smartints-wizard .select-connections-step .engrator-right-panel-content div.footer {
      display: none; }
    .getintio .smartints-wizard .select-connections-step .connection-dropdown p.description {
      min-height: 30px; }
    .getintio .smartints-wizard .configuration-step .trigger label {
      display: block;
      font-family: 'bold'; }
    .getintio .smartints-wizard .configuration-step .row-two-cols {
      display: flex; }
      .getintio .smartints-wizard .configuration-step .row-two-cols .left, .getintio .smartints-wizard .configuration-step .row-two-cols .right {
        flex: 1; }
      .getintio .smartints-wizard .configuration-step .row-two-cols .left {
        padding-right: 15px;
        box-sizing: border-box; }
    .getintio .smartints-wizard .configuration-step .mapping-display {
      border-bottom: 1px solid #fff;
      position: relative;
      padding: 15px; }
      .getintio .smartints-wizard .configuration-step .mapping-display .button-container.danger {
        position: absolute;
        right: 8px;
        top: 8px; }
    .getintio .smartints-wizard .review .button-container.elementary {
      display: block;
      margin: auto; }
      .getintio .smartints-wizard .review .button-container.elementary button {
        margin: 25px auto; }
  .getintio .info-with-refresh-btn .engrator-ui-message {
    padding: 10px 35px; }
  .getintio .info-with-refresh-btn .engrator-ui-message.warning {
    margin-top: 15px; }
    .getintio .info-with-refresh-btn .engrator-ui-message.warning .engrator-ui-icon-exclamation-triangle {
      top: 9px; }
  .getintio .info-with-refresh-btn .flex {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    column-gap: 15px; }
    .getintio .info-with-refresh-btn .flex .engrator-ui-message {
      padding: 10px 15px;
      margin-top: 0;
      flex: 1; }
  .getintio .comments-filtering {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-top: 15px; }
    .getintio .comments-filtering h3 {
      font-size: 14px;
      margin-bottom: 10px; }
    .getintio .comments-filtering > div {
      position: relative;
      flex: 1; }
      .getintio .comments-filtering > div .filters-list {
        margin-top: 10px; }
      .getintio .comments-filtering > div.disabled {
        opacity: 0.4; }
    .getintio .comments-filtering .disabled-overlay {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 1; }
  .getintio .engagement.setup-notifications {
    margin-bottom: 15px; }
    .getintio .engagement.setup-notifications .engrator-ui-message {
      font-size: 12px; }
      .getintio .engagement.setup-notifications .engrator-ui-message a {
        font-size: 12px; }
  .getintio .engagement-popup {
    text-align: center; }
    .getintio .engagement-popup .box {
      padding: 15px; }
      .getintio .engagement-popup .box .button-container {
        margin-top: 25px; }
    .getintio .engagement-popup h1 {
      margin-bottom: 25px; }
  .getintio .engagement-apps-sync-guide {
    text-align: center;
    top: 23px;
    font-family: medium;
    left: 0;
    right: 0; }
    .getintio .engagement-apps-sync-guide .guide-me button {
      background: #009688; }
      .getintio .engagement-apps-sync-guide .guide-me button:hover {
        background: #005a51; }
    .getintio .engagement-apps-sync-guide .guide-me .context-menu-popup {
      width: max-content;
      z-index: 10000;
      top: 65px;
      text-align: left; }
      .getintio .engagement-apps-sync-guide .guide-me .context-menu-popup li {
        padding: 0; }
        .getintio .engagement-apps-sync-guide .guide-me .context-menu-popup li a {
          padding: 10px;
          padding-left: 35px; }
    .getintio .engagement-apps-sync-guide .help-btn-container .popup {
      top: 80px;
      text-align: left; }
    .getintio .engagement-apps-sync-guide.relative-position {
      margin-top: 20px;
      position: relative;
      top: initial;
      bottom: initial;
      right: initial;
      box-shadow: initial;
      display: flex;
      flex-direction: column;
      margin-bottom: 15px; }
      .getintio .engagement-apps-sync-guide.relative-position .inner {
        margin: initial;
        max-width: initial;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column; }
        .getintio .engagement-apps-sync-guide.relative-position .inner > a {
          max-width: 255px;
          width: 100%;
          display: inline-flex;
          box-sizing: border-box;
          margin: 0 10px;
          box-shadow: 0px -1px 5px -1px rgba(0, 0, 0, 0.13);
          border: 1px solid #0050c7;
          padding: 15px 10px;
          padding-left: 50px; }
          .getintio .engagement-apps-sync-guide.relative-position .inner > a img {
            top: 12px; }
    .getintio .engagement-apps-sync-guide .inner {
      max-width: 330px;
      margin: auto; }
      .getintio .engagement-apps-sync-guide .inner > a {
        box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
        background: #ddecff;
        color: #172b4d;
        border-radius: 5px;
        padding: 9px;
        display: block;
        text-decoration: none;
        font-size: 14px;
        position: relative;
        padding-left: 45px; }
        .getintio .engagement-apps-sync-guide .inner > a img {
          position: absolute;
          top: 7px;
          left: 15px; }
        .getintio .engagement-apps-sync-guide .inner > a:hover {
          background: #0050c7;
          color: #fff; }
  .getintio .engrator-ui-message.info .tawk-to {
    margin: auto;
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
    display: block;
    color: #fff;
    background: #0050c7;
    border: 0;
    border-radius: 5px; }
  .getintio .trial-warning-bar {
    cursor: initial;
    display: block;
    height: 100%;
    box-sizing: border-box; }
    .getintio .trial-warning-bar .warning-bar {
      position: initial;
      display: block;
      height: 100%;
      box-shadow: none;
      box-sizing: border-box; }
    .getintio .trial-warning-bar .warning {
      display: block;
      height: 100%;
      box-sizing: border-box;
      padding-top: 10px;
      max-width: 200px; }
      .getintio .trial-warning-bar .warning .contact-sales {
        display: inline-block; }
        .getintio .trial-warning-bar .warning .contact-sales button {
          background: #ca423d;
          margin: auto 5px;
          border-radius: 4px;
          padding: 1px 6px;
          font-size: 12px;
          color: #fff;
          line-height: 12px;
          min-height: 20px; }
      .getintio .trial-warning-bar .warning a {
        background: #ca423d;
        margin: auto 5px;
        border-radius: 4px;
        padding: 1px 6px;
        font-size: 12px;
        color: #fff;
        text-decoration: none;
        font-family: 'medium'; }
        .getintio .trial-warning-bar .warning a:hover {
          text-decoration: underline; }
  .getintio .engagement.start-guide {
    width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 15px 15px 50px;
    border-radius: 4px;
    border: 1px solid #eaeaea;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    margin: 0 20px;
    position: relative; }
    .getintio .engagement.start-guide .info {
      padding-left: 25px; }
      .getintio .engagement.start-guide .info p {
        font-family: 'regular-paragraph';
        padding: 0;
        font-size: 14px; }
      .getintio .engagement.start-guide .info h2 {
        padding-top: 15px; }
    .getintio .engagement.start-guide ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .getintio .engagement.start-guide ul li {
        margin: 25px 0;
        font-family: 'medium';
        text-align: left;
        border: 2px solid #eaeaea;
        border-radius: 4px;
        padding: 20px 5px 5px 20px; }
        .getintio .engagement.start-guide ul li .engrator-ui-icon {
          margin-right: 10px;
          display: none; }
        .getintio .engagement.start-guide ul li h3 {
          border-bottom: 2px solid #eaeaea;
          padding-bottom: 15px;
          padding-top: 15px; }
        .getintio .engagement.start-guide ul li.done {
          color: #4dad5d; }
          .getintio .engagement.start-guide ul li.done .engrator-ui-icon {
            color: #4dad5d; }
        .getintio .engagement.start-guide ul li p {
          font-family: 'regular-paragraph'; }
  @media only screen and (max-width: 1200px) {
    .getintio .engagement.start-guide {
      padding-bottom: 50px; } }
  .getintio .engagement.guide-tooltip {
    position: relative;
    top: 20px;
    left: 0;
    line-height: initial;
    margin-bottom: -50px;
    background: #000;
    min-width: 100px;
    color: #fff;
    text-align: left;
    z-index: 1;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 12px;
    font-family: 'regular'; }
    .getintio .engagement.guide-tooltip .close-icon {
      position: absolute;
      right: -30px;
      top: -10px;
      opacity: 1;
      cursor: pointer; }
      .getintio .engagement.guide-tooltip .close-icon img {
        height: 20px; }
  .getintio .integration-mode .modes {
    display: flex;
    flex-direction: row;
    column-gap: 25px; }
    .getintio .integration-mode .modes > div {
      flex: 50%;
      border: 2px solid #eaeaea;
      border-radius: 4px;
      text-align: center;
      padding: 15px;
      cursor: pointer; }
      .getintio .integration-mode .modes > div:hover {
        border: 2px solid #0050c7; }
  .getintio .in-iframe-apps-need-refresh {
    text-align: center;
    margin: 100px auto;
    font-size: 17px;
    max-width: max-content; }
  .getintio .auth-main-frame {
    display: flex;
    padding: 0;
    margin: 0;
    height: 100%; }
    .getintio .auth-main-frame .footer-links {
      margin-top: 0px;
      color: #989ae4; }
      .getintio .auth-main-frame .footer-links a {
        color: #989ae4;
        font-size: 11px;
        padding: 0 2px; }
    .getintio .auth-main-frame div.left-side {
      height: 100%;
      background: #44168a;
      flex: 1;
      box-sizing: border-box;
      position: relative; }
      .getintio .auth-main-frame div.left-side .slogan {
        position: absolute;
        top: 40%;
        left: 50px; }
        .getintio .auth-main-frame div.left-side .slogan h1, .getintio .auth-main-frame div.left-side .slogan h2 {
          font-family: 'medium';
          color: #fff;
          line-height: 50px; }
        .getintio .auth-main-frame div.left-side .slogan h1 {
          font-size: 40px; }
        .getintio .auth-main-frame div.left-side .slogan h2 {
          font-size: 25px; }
        .getintio .auth-main-frame div.left-side .slogan a.read-more {
          background: #6a38b5;
          text-decoration: none;
          padding: 10px 20px;
          color: #fff;
          border-radius: 25px;
          margin-top: 20px;
          display: block;
          text-align: center;
          max-width: 100px;
          z-index: 1;
          position: relative; }
          .getintio .auth-main-frame div.left-side .slogan a.read-more:hover {
            background: #8b4deb;
            box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
      .getintio .auth-main-frame div.left-side img.bg-graphic {
        position: fixed;
        bottom: 0;
        left: 0; }
    .getintio .auth-main-frame div.right-side {
      height: 100%;
      width: 600px;
      text-align: center;
      position: relative;
      background: #fff; }
      .getintio .auth-main-frame div.right-side div.form {
        background: #fff;
        box-sizing: border-box;
        position: absolute;
        top: calc(50% - 150px);
        left: 100px;
        right: 100px; }
        .getintio .auth-main-frame div.right-side div.form input {
          border: 1px solid #f3f3f3;
          padding: 15px;
          border-radius: 25px; }
        .getintio .auth-main-frame div.right-side div.form div.sign-in-btn button {
          background: #44168a;
          border-radius: 25px;
          width: 100%;
          padding: 0.51rem 0;
          min-height: 52px; }
        .getintio .auth-main-frame div.right-side div.form div.logotype {
          justify-content: center;
          margin-bottom: 50px; }
        .getintio .auth-main-frame div.right-side div.form div.logotype h1 {
          font-size: 30px; }
        .getintio .auth-main-frame div.right-side div.form label {
          text-align: left; }
        .getintio .auth-main-frame div.right-side div.form .button-container.elementary button {
          margin: 25px auto 50px auto; }
  .getintio .workspace-selection {
    position: fixed;
    background: rgba(255, 255, 255, 0.95);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1; }
    .getintio .workspace-selection ul {
      margin: auto;
      display: flex;
      list-style: none;
      padding: 0; }
      .getintio .workspace-selection ul li {
        padding: 25px;
        margin: 0;
        flex-grow: 1;
        max-width: 200px;
        border: 1px solid #eaeaea;
        background: #fff;
        border-radius: 4px; }
  .getintio .cluster-tenants-page .styled-table {
    border-top: 1px solid #dddddd; }
  .getintio .cluster-tenants-page .styled-table tbody tr.active-row {
    color: #009879; }
  .getintio .cluster-tenants-page .styled-table tbody tr {
    border-bottom: 1px solid #dddddd; }
  .getintio .cluster-tenants-page .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3; }
  .getintio .cluster-tenants-page .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879; }
  .getintio .cluster-tenants-page .styled-table th:first-child,
  .getintio .cluster-tenants-page .styled-table td:first-child {
    border-left: 1px solid #dddddd; }
  .getintio .cluster-tenants-page .styled-table th,
  .getintio .cluster-tenants-page .styled-table td {
    padding: 12px 15px;
    border-bottom: 1px solid #dddddd;
    border-right: 1px solid #dddddd; }
  .getintio .cluster-tenants-page .styled-table thead tr {
    text-align: left;
    font-family: 'bold'; }
  .getintio .cluster-tenants-page .styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 14px;
    min-width: 400px;
    width: 100%; }
  .getintio .cluster-tenants-page span.count-failed {
    color: red; }
  .getintio .cluster-tenants-page span.count-ok {
    color: green; }
  .getintio .cluster-tenants-page .styled-table td.Sick {
    background-color: red; }
  .getintio .cluster-tenants-page .styled-table td.Symptoms {
    background-color: orange; }
  .getintio .cluster-tenants-page .styled-table td.Unknown {
    background-color: gray; }
  .getintio .cluster-tenants-page .styled-table td.Starter {
    background-color: lightblue; }
  .getintio .cluster-tenants-page td.actions .button-container {
    margin-bottom: 15px;
    width: 100%; }
    .getintio .cluster-tenants-page td.actions .button-container button {
      width: 100%; }
  .getintio .cluster-tenants-page .tenants-filters {
    display: flex;
    margin-top: 20px;
    flex-direction: row;
    background: #fff;
    padding: 15px;
    border: 1px solid #eaeaea;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
    .getintio .cluster-tenants-page .tenants-filters .query {
      flex-grow: 2; }
    .getintio .cluster-tenants-page .tenants-filters .disabled {
      padding-left: 15px;
      padding-top: 5px; }
    .getintio .cluster-tenants-page .tenants-filters div {
      flex: 1;
      flex-grow: 1; }
    .getintio .cluster-tenants-page .tenants-filters .btn {
      flex: 0 0 100px; }
  .getintio .connection-debugger .response pre {
    white-space: break-spaces;
    word-break: break-all; }
  .getintio .connections-list-page td button {
    text-align: left; }
  .getintio .connections-list-page .app-with-url {
    display: flex;
    align-items: center; }
    .getintio .connections-list-page .app-with-url img {
      margin-right: 15px; }
    .getintio .connections-list-page .app-with-url a {
      text-decoration: none; }
  .getintio .new-connection {
    display: flex; }
    .getintio .new-connection .engrator-ui-message.success {
      margin-top: 15px; }
    .getintio .new-connection .details {
      flex: 1; }
    .getintio .new-connection div[data-sel="url"] .info {
      width: 100%; }
    .getintio .new-connection .info {
      align-items: end;
      width: 400px; }
  .getintio .new-connection .engagement-apps-sync-guide,
  .getintio .connection-details-step .engagement-apps-sync-guide {
    margin-top: 12px; }
    .getintio .new-connection .engagement-apps-sync-guide .noticeable-btn,
    .getintio .connection-details-step .engagement-apps-sync-guide .noticeable-btn {
      margin: auto;
      width: 255px;
      line-height: 47px;
      margin-top: 20px; }
  .getintio .connection-change {
    display: flex;
    flex-direction: column;
    gap: 10px; }
  .getintio .archiver-item-details {
    padding-top: 20px; }
    .getintio .archiver-item-details .html-content {
      line-height: 25px;
      border: 1px solid #eaeaea;
      padding: 15px;
      border-radius: 4px; }
      .getintio .archiver-item-details .html-content a {
        font-size: 14px; }
      .getintio .archiver-item-details .html-content .code.panel {
        background: #f3f3f3;
        margin-bottom: 10px; }
        .getintio .archiver-item-details .html-content .code.panel .codeContent {
          padding: 5px;
          border-radius: 4px; }
          .getintio .archiver-item-details .html-content .code.panel .codeContent pre {
            margin: 0;
            overflow-y: auto; }
    .getintio .archiver-item-details .belongs-to {
      color: #434f5f; }
    .getintio .archiver-item-details .timestamp {
      padding-left: 15px;
      font-size: 12px;
      margin-bottom: 15px; }
    .getintio .archiver-item-details h2 {
      font-family: 'medium'; }
    .getintio .archiver-item-details h2.comments-header {
      margin-top: 50px;
      display: block; }
    .getintio .archiver-item-details .engrator-header h1 {
      font-size: 21px;
      margin-bottom: 15px;
      line-height: 29px; }
    .getintio .archiver-item-details .comment {
      border-radius: 4px;
      margin-top: 10px;
      padding: 15px 0; }
      .getintio .archiver-item-details .comment .comment-header {
        font-size: 12px; }
        .getintio .archiver-item-details .comment .comment-header span {
          font-family: 'medium'; }
      .getintio .archiver-item-details .comment .content {
        margin-top: 15px; }
    .getintio .archiver-item-details table.items-data {
      width: 100%;
      margin: 25px auto;
      border-bottom: 1px solid #eaeaea; }
      .getintio .archiver-item-details table.items-data tr {
        display: block;
        border-top: 1px solid #eaeaea; }
        .getintio .archiver-item-details table.items-data tr td {
          padding: 5px !important;
          border-bottom: none !important; }
        .getintio .archiver-item-details table.items-data tr td:first-child {
          padding: 5px;
          border-right: 1px solid #eaeaea;
          border-bottom: none;
          color: #434f5f;
          font-family: 'bold';
          width: 250px;
          text-align: right;
          vertical-align: top; }
    .getintio .archiver-item-details .attachments {
      margin-top: 15px; }
      .getintio .archiver-item-details .attachments span {
        margin-right: 10px; }
      .getintio .archiver-item-details .attachments a {
        cursor: pointer; }
        .getintio .archiver-item-details .attachments a:hover {
          text-decoration: underline; }
  .getintio .archiver-page span.all-items-count {
    line-height: 36px; }
  .getintio .archiver-search {
    margin-bottom: 15px; }
    .getintio .archiver-search .filters {
      display: flex;
      flex-direction: row; }
      .getintio .archiver-search .filters > div {
        flex: 1;
        flex-shrink: 0;
        box-sizing: border-box;
        padding-right: 15px; }
      .getintio .archiver-search .filters .search-btn {
        flex: 0 0 130px; }
        .getintio .archiver-search .filters .search-btn button {
          margin-top: 30px;
          float: right; }
  .getintio .create-alert .info {
    padding-left: 10px; }
  .getintio .create-alert .add-header .btn {
    display: flex; }
    .getintio .create-alert .add-header .btn .button-container {
      align-self: flex-end; }
  .getintio .create-alert .headers-list {
    list-style: none;
    margin: 0;
    padding: 15px 0 0 0; }
  .getintio .anim-blinking {
    animation-name: anim-blinking;
    animation-duration: 2s;
    animation-iteration-count: infinite; }
  .getintio .anim-blinking-fade {
    animation-name: anim-blinking-fade-out;
    animation-duration: 1s;
    animation-iteration-count: infinite; }
  .getintio .anim-fade-in {
    animation-name: anim-fade-in;
    animation-duration: 1s;
    animation-iteration-count: 1; }
  .getintio .engrator-ui-icon-spinner svg,
  .getintio .engrator-ui-icon-spinnerwhite svg,
  .getintio .engrator-ui-icon-statusstarted svg {
    animation-name: ckw;
    animation-duration: 1s;
    animation-iteration-count: infinite; }
  .getintio .new-entry {
    animation-name: anim-fade-in;
    animation-duration: 1s;
    animation-iteration-count: 1; }

@keyframes ckw {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@-webkit-keyframes anim-slide {
  0% {
    transform: translateX(-900px); }
  100% {
    transform: translateX(0); } }

@-moz-keyframes anim-slide {
  0% {
    transform: translateX(-900px); }
  100% {
    transform: translateX(0); } }

@keyframes anim-slide {
  0% {
    transform: translateX(900px); }
  100% {
    transform: translateX(0); } }

@keyframes anim-blinking {
  50% {
    border-color: #0050c7; } }

@keyframes anim-blinking-fade-out {
  0% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes anim-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
  .getintio .ui-notifications {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: 10;
    background: #4dad5d;
    border-radius: 4px;
    padding: 15px;
    color: #fff; }
  .getintio .pipeline-settings .footer .engrator-buttons-bar {
    margin: 0; }
    .getintio .pipeline-settings .footer .engrator-buttons-bar .button-container.danger {
      margin-right: 100px; }
  .getintio .pipeline-settings .working-hours {
    display: flex; }
    .getintio .pipeline-settings .working-hours > div {
      width: 5rem;
      margin-right: 15px; }
  .getintio .fullscreen-modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.35);
    z-index: 10000; }
    .getintio .fullscreen-modal > .close-clickable {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: transparent; }
    .getintio .fullscreen-modal > .inner {
      width: 100%;
      max-width: 800px;
      position: absolute;
      top: 0;
      right: -100%;
      bottom: 0;
      transition: 0.2s; }
      .getintio .fullscreen-modal > .inner.disappear {
        opacity: 0;
        transition: 0.15s; }
      .getintio .fullscreen-modal > .inner.visible {
        right: 0; }
      .getintio .fullscreen-modal > .inner > h3 {
        right: 0;
        width: 100%;
        text-align: left;
        position: absolute;
        font-family: 'bold';
        color: #0050c7;
        padding-left: 50px;
        line-height: 70px;
        font-size: 21px;
        z-index: 10001;
        background: white;
        box-sizing: border-box;
        box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
        .getintio .fullscreen-modal > .inner > h3 .close-icon {
          position: absolute;
          top: 22px;
          right: 32px;
          opacity: 1;
          cursor: pointer;
          margin-right: 20px;
          line-height: 25px; }
      .getintio .fullscreen-modal > .inner > .engrator-buttons-bar {
        position: absolute;
        bottom: 0;
        background: white;
        right: 0px;
        box-sizing: border-box;
        width: 100%;
        padding: 20px 20px 20px 0px;
        box-shadow: 0px -1px 5px -1px rgba(0, 0, 0, 0.13); }
    .getintio .fullscreen-modal > .engrator-ui-message.error {
      position: absolute;
      bottom: 29px;
      z-index: 1;
      width: 100%;
      max-width: 800px;
      right: 0;
      box-sizing: border-box;
      padding-left: 15px; }
    .getintio .fullscreen-modal.no-btns > .inner > .box {
      bottom: 0px; }
    .getintio .fullscreen-modal.no-btns > .inner > .engrator-buttons-bar {
      display: none; }
    .getintio .fullscreen-modal .inner > .box {
      margin: initial;
      text-align: left;
      position: absolute;
      right: 0;
      top: 70px;
      bottom: 75px;
      width: 100%;
      overflow-y: auto;
      background: white;
      box-sizing: border-box;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      padding: 20px 50px; }
    .getintio .fullscreen-modal.hide-footer .box {
      bottom: 0px; }
    .getintio .fullscreen-modal.hide-footer > .inner > .engrator-buttons-bar {
      display: none; }
    .getintio .fullscreen-modal.large > .inner {
      max-width: 1024px; }
    .getintio .fullscreen-modal.maximized > .inner {
      max-width: 1350px; }
    .getintio .fullscreen-modal.depth-1 {
      z-index: 10001; }
      .getintio .fullscreen-modal.depth-1.large > .inner {
        max-width: 934px; }
      .getintio .fullscreen-modal.depth-1.maximized > .inner {
        max-width: 1250px; }
    .getintio .fullscreen-modal.depth-2 {
      z-index: 10002; }
      .getintio .fullscreen-modal.depth-2.large > .inner {
        max-width: 914px; }
      .getintio .fullscreen-modal.depth-2.maximized > .inner {
        max-width: 1230px; }
  .getintio .persistence-loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.7);
    z-index: 10001; }
    .getintio .persistence-loader.modal {
      overflow-y: auto; }
      .getintio .persistence-loader.modal .box {
        border-radius: 4px;
        margin-bottom: 10rem; }
    .getintio .persistence-loader.highest-index {
      z-index: 99999; }
    .getintio .persistence-loader.confirmation-modal {
      text-align: left; }
      .getintio .persistence-loader.confirmation-modal .box h3 {
        text-align: left; }
    .getintio .persistence-loader .box {
      background-color: #fff;
      max-width: 600px;
      display: block;
      margin: auto;
      padding: 25px;
      margin-top: 10rem;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33); }
      .getintio .persistence-loader .box.wide {
        width: 35rem;
        margin-top: 5rem; }
      .getintio .persistence-loader .box.less-padding {
        padding: 2rem; }
      .getintio .persistence-loader .box.left-aligned {
        text-align: left; }
        .getintio .persistence-loader .box.left-aligned h3 {
          text-align: left; }
      .getintio .persistence-loader .box .button-container {
        margin-top: 2rem; }
        .getintio .persistence-loader .box .button-container button {
          margin: auto; }
      .getintio .persistence-loader .box .engrator-ui-message.error {
        text-align: center; }
      .getintio .persistence-loader .box h3 {
        text-align: center;
        margin-bottom: 20px; }
      .getintio .persistence-loader .box .loader {
        text-align: center; }
  .getintio .app-logotype {
    vertical-align: middle;
    line-height: 70px;
    height: 70px;
    padding: 0rem 1rem;
    font-weight: bold;
    position: relative; }
    .getintio .app-logotype img {
      top: 12px;
      position: relative;
      margin-right: 1rem; }
    .getintio .app-logotype span {
      position: absolute;
      left: 6rem; }
  .getintio .pipeline-mode {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10000; }
    .getintio .pipeline-mode .content {
      background-color: #fff;
      display: block;
      margin: auto;
      max-width: 40rem;
      margin-top: 25px;
      padding: 15px;
      box-shadow: -1px 1px 34px -1px rgba(0, 0, 0, 0.33); }
      .getintio .pipeline-mode .content .header {
        text-align: center; }
        .getintio .pipeline-mode .content .header h1 {
          color: #0050c7; }
        .getintio .pipeline-mode .content .header p {
          font-size: 14px;
          color: #434f5f; }
      .getintio .pipeline-mode .content .tile {
        padding: 15px; }
        .getintio .pipeline-mode .content .tile h3 {
          text-align: center; }
        .getintio .pipeline-mode .content .tile .engrator-ui-tile {
          max-width: 12rem;
          display: block;
          margin: auto;
          cursor: pointer; }
        .getintio .pipeline-mode .content .tile .engrator-ui-tile:hover {
          background-color: rgba(123, 123, 123, 0.05); }
  .getintio .integration-designer {
    padding: 0px 50px;
    width: 880px;
    display: block;
    position: relative;
    color: #353535;
    margin: 0px auto auto;
    height: 400px;
    padding-top: 20px; }
    .getintio .integration-designer h2 {
      font-size: 17px; }
    .getintio .integration-designer .row-two-cols {
      display: flex;
      column-gap: 15px; }
      .getintio .integration-designer .row-two-cols > div {
        flex: 1; }
    .getintio .integration-designer .default-mapping {
      display: flex;
      flex-direction: row;
      column-gap: 15px; }
      .getintio .integration-designer .default-mapping > div {
        flex: 1; }
    .getintio .integration-designer .empty-value-handler {
      display: flex;
      flex-direction: row;
      column-gap: 15px; }
      .getintio .integration-designer .empty-value-handler > div {
        flex: 1; }
    .getintio .integration-designer .row-two-cols.mapped-options-header {
      align-items: start; }
      .getintio .integration-designer .row-two-cols.mapped-options-header .left {
        line-height: 35px; }
      .getintio .integration-designer .row-two-cols.mapped-options-header .right {
        background: none; }
        .getintio .integration-designer .row-two-cols.mapped-options-header .right .engrator-buttons-bar {
          margin-top: 0px; }
    .getintio .integration-designer .items-filter-icon {
      padding: 5px 6px;
      border: 2px solid #6554c0;
      border-radius: 4px;
      position: absolute;
      left: 115px;
      top: 262px;
      margin-top: -18px;
      background: #fff;
      color: #aaa;
      background: #fff;
      z-index: 1;
      cursor: pointer; }
      .getintio .integration-designer .items-filter-icon.right {
        right: 115px;
        left: initial; }
      .getintio .integration-designer .items-filter-icon:hover, .getintio .integration-designer .items-filter-icon.active {
        color: #fff;
        background: #6554c0; }
    .getintio .integration-designer > .btns {
      width: 100%;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0; }
      .getintio .integration-designer > .btns .engrator-ui-message.error {
        text-align: center; }
      .getintio .integration-designer > .btns .button-container.elementary button {
        margin: auto;
        margin-top: 15px; }
    .getintio .integration-designer #canvas {
      display: block;
      position: absolute;
      top: 0;
      left: 270px; }
    .getintio .integration-designer .app-selection-wizard {
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px; }
      .getintio .integration-designer .app-selection-wizard .app-logotype {
        padding: 0; }
      .getintio .integration-designer .app-selection-wizard .select-app-step ul {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0; }
        .getintio .integration-designer .app-selection-wizard .select-app-step ul li {
          flex: 1 1 30%;
          width: 100%;
          flex-grow: 1;
          height: 60px;
          cursor: pointer;
          flex-direction: row;
          font-family: 'medium';
          box-sizing: border-box;
          border: 1px solid #ccc;
          border-radius: 4px; }
          .getintio .integration-designer .app-selection-wizard .select-app-step ul li:hover {
            background: #f3f3f3;
            border-radius: 4px; }
          .getintio .integration-designer .app-selection-wizard .select-app-step ul li img {
            top: 4px;
            left: 15px; }
          .getintio .integration-designer .app-selection-wizard .select-app-step ul li span {
            top: 8px;
            left: 80px; }
          .getintio .integration-designer .app-selection-wizard .select-app-step ul li a.missing-app {
            position: relative;
            box-sizing: border-box;
            padding-left: 80px;
            padding-top: 20px;
            text-decoration: none;
            display: block; }
            .getintio .integration-designer .app-selection-wizard .select-app-step ul li a.missing-app img {
              position: absolute; }
      .getintio .integration-designer .app-selection-wizard .connection-details-step label {
        margin-bottom: 5px; }
    .getintio .integration-designer .engrator-ui-icon-settings {
      position: absolute;
      left: initial;
      right: 0px;
      top: 30px;
      width: 20px;
      cursor: pointer; }
    .getintio .integration-designer .close-icon {
      cursor: pointer;
      opacity: 0.6;
      position: absolute;
      right: 10px;
      top: 6px; }
    .getintio .integration-designer .select-app {
      display: block;
      font-weight: bold;
      text-align: center;
      position: absolute;
      top: 200px; }
      .getintio .integration-designer .select-app .app-container {
        border: 2px solid #ccc;
        border-radius: 7px;
        background: #fff;
        height: 120px;
        width: 130px;
        cursor: pointer; }
        .getintio .integration-designer .select-app .app-container .inner {
          height: 100%; }
      .getintio .integration-designer .select-app .app-container:hover {
        border: 2px solid #0153cc; }
      .getintio .integration-designer .select-app .app-logotype {
        line-height: 43px;
        height: 60px; }
        .getintio .integration-designer .select-app .app-logotype img {
          margin-right: 0;
          top: 25px; }
      .getintio .integration-designer .select-app span.software-name {
        font-family: 'medium';
        text-transform: uppercase;
        top: 18px;
        font-size: 14px;
        position: relative; }
      .getintio .integration-designer .select-app div.select-app-btn {
        line-height: 100px;
        border: 2px solid #ccc;
        border-radius: 7px;
        background: #fff;
        height: 100px;
        width: 120px;
        cursor: pointer;
        font-family: 'medium';
        text-transform: uppercase; }
        .getintio .integration-designer .select-app div.select-app-btn:hover {
          border: 2px solid #0153cc; }
      .getintio .integration-designer .select-app div.add-type {
        width: 15px;
        height: 15px;
        border: 2px solid #0153cc;
        background: #0153cc;
        color: #fff;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: -8px;
        font-size: 13px;
        border-radius: 5px;
        display: block;
        z-index: 999; }
        .getintio .integration-designer .select-app div.add-type:hover {
          background: #4dad5d; }
      .getintio .integration-designer .select-app.left {
        left: 0; }
      .getintio .integration-designer .select-app.right {
        right: 0; }
        .getintio .integration-designer .select-app.right div.add-type {
          left: -8px; }
        .getintio .integration-designer .select-app.right .selection-popup {
          left: -300px; }
    .getintio .integration-designer .selection-popup {
      position: absolute;
      display: block;
      width: 400px;
      z-index: 999999;
      border: 1px solid #ccc;
      background: #fff;
      border-radius: 7px;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      padding: 15px;
      box-sizing: border-box;
      top: 40px;
      left: 70px; }
      .getintio .integration-designer .selection-popup h4 {
        margin-bottom: 10px; }
      .getintio .integration-designer .selection-popup .app-logotype {
        height: 45px;
        position: relative; }
        .getintio .integration-designer .selection-popup .app-logotype img {
          top: 5px;
          left: 10px;
          position: absolute; }
        .getintio .integration-designer .selection-popup .app-logotype span {
          top: 0;
          line-height: 45px; }
      .getintio .integration-designer .selection-popup ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 14px;
        height: 195px;
        overflow: auto;
        border: 1px solid #ccc;
        margin-bottom: 15px; }
        .getintio .integration-designer .selection-popup ul li {
          text-align: left;
          margin: 0;
          cursor: pointer;
          line-height: 30px;
          padding: 0 10px; }
          .getintio .integration-designer .selection-popup ul li:hover {
            background: #eee; }
      .getintio .integration-designer .selection-popup .connection-details-step, .getintio .integration-designer .selection-popup .edit-app {
        text-align: left; }
        .getintio .integration-designer .selection-popup .connection-details-step label, .getintio .integration-designer .selection-popup .edit-app label {
          color: #434f5f;
          font-size: 14px;
          display: block;
          padding-bottom: 5px; }
        .getintio .integration-designer .selection-popup .connection-details-step .subvalue, .getintio .integration-designer .selection-popup .edit-app .subvalue {
          color: #434f5f;
          margin: 0;
          padding: 0;
          font-size: 10px; }
        .getintio .integration-designer .selection-popup .connection-details-step .engrator-form-group, .getintio .integration-designer .selection-popup .edit-app .engrator-form-group {
          margin: 0 0 10px 0; }
        .getintio .integration-designer .selection-popup .connection-details-step .engrator-ui-checkbox label, .getintio .integration-designer .selection-popup .edit-app .engrator-ui-checkbox label {
          display: inline-block; }
        .getintio .integration-designer .selection-popup .connection-details-step > div > .engrator-buttons-bar, .getintio .integration-designer .selection-popup .edit-app > div > .engrator-buttons-bar {
          margin-top: 20px; }
        .getintio .integration-designer .selection-popup .connection-details-step .create-new-btn, .getintio .integration-designer .selection-popup .edit-app .create-new-btn {
          float: right;
          margin-top: 10px; }
        .getintio .integration-designer .selection-popup .connection-details-step .connection-details, .getintio .integration-designer .selection-popup .edit-app .connection-details {
          margin-bottom: 15px; }
      .getintio .integration-designer .selection-popup .type-selection-wizard > .engrator-buttons-bar {
        margin-top: 15px; }
    .getintio .integration-designer .type-selection-wizard .app-logotype {
      line-height: inherit;
      height: 30px;
      padding: 0; }
      .getintio .integration-designer .type-selection-wizard .app-logotype img {
        top: inherit;
        display: block;
        margin: auto; }
    .getintio .integration-designer .type-box {
      font-family: medium;
      background: #fff;
      border-radius: 4px;
      border: 2px solid #ccc;
      font-size: 14px;
      padding: 5px 9px;
      min-width: 60px;
      width: 125px;
      z-index: 1;
      text-align: center;
      position: absolute; }
    .getintio .integration-designer .types-mappings {
      width: 500px;
      margin: auto;
      display: block; }
      .getintio .integration-designer .types-mappings .add-type-mapping-row {
        display: flex;
        column-gap: 15px;
        flex-direction: row; }
        .getintio .integration-designer .types-mappings .add-type-mapping-row .left, .getintio .integration-designer .types-mappings .add-type-mapping-row .right {
          width: 100%; }
      .getintio .integration-designer .types-mappings .add-type-mapping {
        margin: auto;
        display: block;
        width: fit-content; }
      .getintio .integration-designer .types-mappings .types-mapping {
        position: relative;
        width: 100%;
        height: 35px;
        margin-bottom: 35px; }
        .getintio .integration-designer .types-mappings .types-mapping.is-automapped {
          opacity: 0.5; }
        .getintio .integration-designer .types-mappings .types-mapping .automapping-in-progress {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: transparent;
          z-index: 10; }
        .getintio .integration-designer .types-mappings .types-mapping .type-boxes {
          position: relative;
          height: 40px; }
          .getintio .integration-designer .types-mappings .types-mapping .type-boxes > .engrator-ui-icon-edit {
            position: absolute;
            left: 50%;
            margin-left: -15px;
            cursor: pointer;
            z-index: 999;
            width: 30px;
            margin-top: 18px;
            color: rgba(0, 0, 0, 0.5);
            font-size: 17px; }
          .getintio .integration-designer .types-mappings .types-mapping .type-boxes:hover {
            cursor: pointer; }
            .getintio .integration-designer .types-mappings .types-mapping .type-boxes:hover .type-box {
              border: 2px solid #0153cc; }
            .getintio .integration-designer .types-mappings .types-mapping .type-boxes:hover > hr {
              border-top: 1px solid #0153cc; }
            .getintio .integration-designer .types-mappings .types-mapping .type-boxes:hover > .engrator-ui-icon-edit {
              color: #0153cc; }
            .getintio .integration-designer .types-mappings .types-mapping .type-boxes:hover .type-configs .label {
              border: 1px solid #0153cc; }
        .getintio .integration-designer .types-mappings .types-mapping hr {
          background-color: transparent;
          border-top: 1px solid #cccccc;
          position: absolute;
          left: 130px;
          right: 127px;
          top: 8px;
          box-shadow: none; }
        .getintio .integration-designer .types-mappings .types-mapping .type-box.left {
          left: 0px; }
        .getintio .integration-designer .types-mappings .types-mapping .type-box.right {
          right: 0px; }
        .getintio .integration-designer .types-mappings .types-mapping .type-configs {
          display: flex;
          justify-content: center;
          position: absolute;
          top: -8px;
          text-align: center;
          z-index: 9999;
          width: 250px;
          left: 130px; }
        .getintio .integration-designer .types-mappings .types-mapping .cog {
          cursor: pointer;
          width: 30px;
          height: 30px;
          position: absolute; }
        .getintio .integration-designer .types-mappings .types-mapping > .cog {
          position: absolute;
          left: 50%;
          margin-left: -15px;
          cursor: pointer;
          z-index: 999;
          width: 30px;
          margin-top: 15px; }
        .getintio .integration-designer .types-mappings .types-mapping:hover > .cog {
          display: block; }
        .getintio .integration-designer .types-mappings .types-mapping .ui-context-menu {
          position: absolute;
          right: -50px;
          top: 0px; }
          .getintio .integration-designer .types-mappings .types-mapping .ui-context-menu .dots {
            background: #fff;
            border: 2px solid #ccc;
            border-radius: 4px; }
            .getintio .integration-designer .types-mappings .types-mapping .ui-context-menu .dots:hover {
              border: 2px solid #0050c7; }
        .getintio .integration-designer .types-mappings .types-mapping > .warning-flag {
          background: transparent;
          right: -90px;
          left: auto;
          top: -20px;
          z-index: 9; }
        .getintio .integration-designer .types-mappings .types-mapping.status-Disabled .type-boxes {
          opacity: 0.5; }
      .getintio .integration-designer .types-mappings .not-mapped {
        position: relative; }
        .getintio .integration-designer .types-mappings .not-mapped .type-box {
          position: relative;
          margin-bottom: 10px; }
          .getintio .integration-designer .types-mappings .not-mapped .type-box:after {
            clear: both;
            content: ""; }
      .getintio .integration-designer .types-mappings .not-mapped.left {
        float: left; }
      .getintio .integration-designer .types-mappings .not-mapped.right {
        float: right; }
        .getintio .integration-designer .types-mappings .not-mapped.right .select-type-box {
          display: flex;
          justify-content: right; }
    .getintio .integration-designer .section-with-help {
      display: flex;
      column-gap: 50px; }
      .getintio .integration-designer .section-with-help > .right {
        width: 100%;
        max-width: 300px;
        font-size: 12px; }
        .getintio .integration-designer .section-with-help > .right ul {
          padding: 15px 0 0;
          margin: 0;
          list-style: none; }
          .getintio .integration-designer .section-with-help > .right ul li {
            padding: 5px 0; }
      .getintio .integration-designer .section-with-help > .left {
        flex-grow: 1; }
    .getintio .integration-designer .type-mapping-configuration > .inner {
      background: #fff;
      width: 100%;
      display: block;
      box-sizing: border-box;
      position: relative; }
      .getintio .integration-designer .type-mapping-configuration > .inner > h1 {
        margin-bottom: 20px;
        text-align: center; }
      .getintio .integration-designer .type-mapping-configuration > .inner > .engrator-buttons-bar {
        margin-top: 20px; }
    .getintio .integration-designer .type-mapping-configuration .add-field-mapping-btn button {
      margin: 20px auto;
      max-width: 300px;
      width: 100%; }
    .getintio .integration-designer .type-mapping-configuration .row-two-cols.mapped-options-header .left, .getintio .integration-designer .type-mapping-configuration .row-two-cols.mapped-options-header .right {
      background: #fff; }
    .getintio .integration-designer .type-mapping-configuration .values-mapping {
      z-index: 99999; }
      .getintio .integration-designer .type-mapping-configuration .values-mapping > .inner {
        width: 100%;
        height: 100%;
        overflow: auto;
        box-sizing: border-box;
        margin: 0 auto; }
        .getintio .integration-designer .type-mapping-configuration .values-mapping > .inner .btns .button-container.secondary button {
          margin: auto;
          margin-top: 15px; }
    .getintio .integration-designer .type-mapping-configuration .engrator-buttons-bar .col.left .button-container.danger {
      float: left; }
    .getintio .integration-designer .type-mapping-configuration .status-transition-configuration {
      padding-top: 15px; }
      .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .status-field-picker {
        padding: 15px; }
      .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .statuses-value-mappings .values-mapping {
        margin-top: 15px;
        position: initial;
        padding: 0; }
        .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .statuses-value-mappings .values-mapping h1, .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .statuses-value-mappings .values-mapping .types-header, .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .statuses-value-mappings .values-mapping .btns {
          display: none; }
        .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .statuses-value-mappings .values-mapping .cog {
          position: absolute;
          left: initial;
          right: 1px;
          top: 26px; }
      .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .status-properties {
        background: rgba(255, 255, 255, 0.7);
        z-index: 10; }
        .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .status-properties > .content {
          background: #fff; }
          .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .status-properties > .content .values-mapping {
            margin-top: 15px;
            padding: 0;
            top: 40px;
            bottom: 80px; }
          .getintio .integration-designer .type-mapping-configuration .status-transition-configuration .status-properties > .content .btns button {
            margin: auto;
            margin-top: 15px; }
    .getintio .integration-designer .type-mapping-configuration .advanced .advanced-sync .advanced-sync-options {
      list-style: none;
      margin: 0;
      padding: 0; }
      .getintio .integration-designer .type-mapping-configuration .advanced .advanced-sync .advanced-sync-options li {
        margin-top: 2px;
        display: flex; }
        .getintio .integration-designer .type-mapping-configuration .advanced .advanced-sync .advanced-sync-options li.bigger-space {
          margin-top: 10px; }
    .getintio .integration-designer .type-mapping-configuration .advanced .advanced-sync h4 {
      margin: 15px 0; }
    .getintio .integration-designer span.label {
      color: #8c8c8c;
      border: 1px solid #b7b7b7;
      font-size: 9px;
      padding: 2px 5px;
      margin-right: 5px;
      margin-left: 5px;
      display: inline-block;
      height: 13px; }
      .getintio .integration-designer span.label .engrator-ui-icon svg {
        height: 12px;
        width: auto;
        opacity: 0.5; }
      .getintio .integration-designer span.label:hover {
        cursor: pointer;
        border-color: #0153cc; }
    .getintio .integration-designer .engrator-ui-message.error {
      font-size: 12px; }
    .getintio .integration-designer .select-type-box.not-picked {
      color: rgba(0, 0, 0, 0.5); }
    .getintio .integration-designer .select-type-box:hover .type-box {
      cursor: pointer;
      border: 2px solid #0153cc; }
  .getintio .mapping-display {
    position: relative;
    margin-bottom: 15px;
    height: 55px;
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer; }
    .getintio .mapping-display .value {
      line-height: 55px; }
    .getintio .mapping-display:hover {
      border: 2px solid #0050c7; }
    .getintio .mapping-display .warning-flag {
      padding-top: 0px;
      box-sizing: border-box;
      z-index: 1; }
      .getintio .mapping-display .warning-flag .engrator-ui-icon {
        margin-top: 18px;
        display: block; }
    .getintio .mapping-display .button-container.danger {
      position: absolute;
      right: 8px;
      top: 8px; }
    .getintio .mapping-display .options {
      position: absolute;
      bottom: 3px;
      left: 50%;
      right: 0;
      max-width: 240px;
      width: 100%;
      line-height: 10px;
      display: flex;
      margin-left: -120px; }
      .getintio .mapping-display .options .label {
        margin-top: 4px;
        display: inline-block;
        flex: 1;
        opacity: 0.3;
        border: 0; }
        .getintio .mapping-display .options .label .engrator-ui-icon {
          display: none;
          padding-right: 3px; }
          .getintio .mapping-display .options .label .engrator-ui-icon svg {
            width: 10px;
            height: 10px; }
        .getintio .mapping-display .options .label.active {
          opacity: 1; }
          .getintio .mapping-display .options .label.active .engrator-ui-icon {
            display: initial; }
        .getintio .mapping-display .options .label.not-clickable {
          cursor: initial; }
    .getintio .mapping-display .value-mappings {
      text-align: center;
      bottom: -3px;
      left: 50%;
      width: 130px;
      margin-left: -65px; }
    .getintio .mapping-display .delete {
      top: 4px;
      right: 5px;
      position: absolute;
      cursor: pointer;
      line-height: initial; }
    .getintio .mapping-display:hover {
      background: #fff; }
      .getintio .mapping-display:hover .delete {
        display: initial; }
  .getintio .types-header {
    font-family: 'bold';
    text-align: center;
    margin-top: 15px;
    display: flex;
    text-align: center; }
    .getintio .types-header p {
      font-family: 'regular';
      font-size: 12px; }
  .getintio .fields-manager h1 {
    font-size: 17px;
    text-align: center;
    margin-bottom: 15px; }
  .getintio .fields-manager .type-mapping-fields {
    padding: 15px 0; }
    .getintio .fields-manager .type-mapping-fields .add-field-mapping-btn {
      margin-bottom: 15px; }
      .getintio .fields-manager .type-mapping-fields .add-field-mapping-btn button {
        margin: auto; }
  .getintio .field-mapping-item {
    display: flex;
    column-gap: 10px; }
    .getintio .field-mapping-item .left, .getintio .field-mapping-item .right {
      flex: 1;
      font-family: 'medium';
      position: relative;
      background: #f3f3f3;
      border-radius: 4px;
      box-sizing: border-box; }
      .getintio .field-mapping-item .left.center, .getintio .field-mapping-item .right.center {
        text-align: center; }
    .getintio .field-mapping-item.right-buttons .left {
      flex: initial; }
    .getintio .field-mapping-item.right-buttons .right {
      flex: 1; }
  .getintio .plugins-bar {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 45px;
    height: 37px;
    gap: 20px; }
  .getintio .add-mapping {
    line-height: initial;
    margin-bottom: 15px;
    margin-top: 10px; }
    .getintio .add-mapping .header {
      margin-bottom: 10px; }
    .getintio .add-mapping .row-two-cols {
      display: flex;
      column-gap: 15px; }
      .getintio .add-mapping .row-two-cols .left, .getintio .add-mapping .row-two-cols .right {
        flex: 1;
        box-sizing: border-box; }
    .getintio .add-mapping .header {
      margin-bottom: 10px; }
    .getintio .add-mapping .engrator-buttons-bar {
      margin-top: 10px; }
    .getintio .add-mapping label {
      display: block; }
  .getintio .direction-picker {
    flex: 1;
    display: flex;
    width: 100%;
    max-width: 120px;
    border: 0;
    padding: 7px 0px 0px;
    column-gap: 5px;
    height: 30px; }
    .getintio .direction-picker .dir-btn {
      cursor: pointer;
      justify-content: center;
      align-items: center;
      flex: 1;
      border-radius: 4px;
      border: 2px solid #ccc;
      box-sizing: border-box;
      display: flex; }
      .getintio .direction-picker .dir-btn.selected, .getintio .direction-picker .dir-btn:hover {
        border: 2px solid #0050c7; }
  .getintio .map-fields-container {
    padding-top: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
    position: relative; }
    .getintio .map-fields-container > p {
      color: rgba(0, 0, 0, 0.5);
      margin: 0; }
    .getintio .map-fields-container .map-fields {
      position: relative;
      font-size: 17px;
      padding: 10px 0;
      padding-right: 100px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      column-gap: 10px;
      flex-wrap: wrap; }
      .getintio .map-fields-container .map-fields > .button-container {
        position: absolute;
        top: 50%;
        margin-top: -18px;
        right: 0; }
      .getintio .map-fields-container .map-fields .ui-dropdown {
        text-align: left; }
        .getintio .map-fields-container .map-fields .ui-dropdown .options-list {
          margin-top: 5px; }
        .getintio .map-fields-container .map-fields .ui-dropdown .selected-value {
          font-size: 17px;
          font-family: 'medium';
          color: #0050c7;
          text-align: center;
          border: 2px solid transparent;
          width: max-content; }
          .getintio .map-fields-container .map-fields .ui-dropdown .selected-value .engrator-ui-icon-chevron-up, .getintio .map-fields-container .map-fields .ui-dropdown .selected-value .engrator-ui-icon-chevron-down {
            display: none; }
        .getintio .map-fields-container .map-fields .ui-dropdown.current .selected-value::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0.1em;
          background-color: #0050c7;
          opacity: 1;
          animation-name: anim-fade-in;
          animation-duration: 1.5s;
          animation-iteration-count: infinite; }
        .getintio .map-fields-container .map-fields .ui-dropdown.current .selected-value .empty:hover {
          color: #0050c7; }
      .getintio .map-fields-container .map-fields .elementary {
        margin-left: 15px; }
      .getintio .map-fields-container .map-fields .break {
        flex-basis: 100%;
        width: 0; }
      .getintio .map-fields-container .map-fields .destination-option {
        display: flex;
        align-items: center;
        gap: 10px; }
  .getintio .app-selection-wizard .configure-connection,
  .getintio .fullscreen-modal .configure-connection {
    display: flex;
    column-gap: 50px; }
    .getintio .app-selection-wizard .configure-connection form, .getintio .app-selection-wizard .configure-connection .details,
    .getintio .fullscreen-modal .configure-connection form,
    .getintio .fullscreen-modal .configure-connection .details {
      flex-grow: 1; }
    .getintio .app-selection-wizard .configure-connection > .info,
    .getintio .fullscreen-modal .configure-connection > .info {
      min-width: 300px;
      display: flex;
      justify-content: center;
      flex-direction: row; }
  .getintio .map-fields-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px; }
  .getintio .warning-flag {
    width: 40px;
    height: 100%;
    background: #fffae6;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    padding-top: 25px;
    box-sizing: border-box;
    color: #434f5f;
    font-size: 14px;
    z-index: 500; }
    .getintio .warning-flag .engrator-ui-icon {
      color: #ef8a33; }
    .getintio .warning-flag .text-popup {
      position: absolute;
      left: 45px;
      top: 10px;
      display: none;
      min-width: 200px;
      box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
      background: #fff;
      border-radius: 4px;
      z-index: 999;
      font-size: 12px;
      padding: 10px;
      text-align: left; }
    .getintio .warning-flag:hover .text-popup {
      display: block; }
  .getintio .no-padding-page .designer-top {
    left: 250px; }
  .getintio .designer-top {
    position: absolute;
    top: 20px;
    left: 80px;
    right: 0; }
    .getintio .designer-top .engrator-ui-entity-status.disabled {
      border: 1px solid rgba(0, 0, 0, 0.5);
      background: #d0180d;
      color: #fff; }
    .getintio .designer-top .engrator-ui-tag {
      margin-left: 15px; }
    .getintio .designer-top .button-container {
      display: inline-block;
      margin: 0 1rem 0 0; }
    .getintio .designer-top .inline-name .engrator-ui-icon-edit {
      left: 10px;
      bottom: 8px;
      position: absolute; }
    .getintio .designer-top .inline-name input {
      text-align: left;
      background: transparent;
      font-size: 17px;
      border: 1px solid transparent;
      padding-left: 30px;
      box-sizing: border-box; }
    .getintio .designer-top .inline-name:hover input {
      background: #fff;
      border: 1px solid #eaeaea; }
    .getintio .designer-top .inline-name:hover .engrator-ui-icon-edit {
      display: initial; }
    .getintio .designer-top .more-btn {
      display: inline-block;
      position: relative; }
      .getintio .designer-top .more-btn .popup {
        position: absolute;
        z-index: 1;
        top: 45px;
        box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
        background: #fff;
        border-radius: 4px; }
        .getintio .designer-top .more-btn .popup ul {
          margin: 0;
          padding: 0;
          list-style: none; }
          .getintio .designer-top .more-btn .popup ul li {
            padding: 10px 15px 10px 40px;
            font-family: 'medium';
            position: relative;
            cursor: pointer; }
            .getintio .designer-top .more-btn .popup ul li .engrator-ui-icon {
              position: absolute;
              top: 12px;
              left: 13px; }
            .getintio .designer-top .more-btn .popup ul li:hover {
              background: #f3f3f3; }
  .getintio .help-btn-container {
    position: relative;
    display: inline-block; }
  .getintio .context-menu-popup {
    position: absolute;
    z-index: 10;
    top: 45px;
    box-shadow: -1px 1px 4px -1px rgba(0, 0, 0, 0.33);
    background: #fff;
    border-radius: 4px;
    width: 225px;
    width: 225px; }
    .getintio .context-menu-popup ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .getintio .context-menu-popup ul li {
        padding: 10px 15px;
        font-family: 'medium';
        position: relative;
        cursor: pointer; }
        .getintio .context-menu-popup ul li .engrator-ui-icon {
          position: absolute;
          top: 12px;
          left: 13px; }
        .getintio .context-menu-popup ul li a {
          width: 100%;
          display: block;
          text-decoration: none;
          text-align: left;
          color: #434f5f; }
          .getintio .context-menu-popup ul li a .engrator-ui-icon {
            margin-right: 10px; }
        .getintio .context-menu-popup ul li:hover {
          background: #f3f3f3; }
