.engrator-header {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    min-height: 4rem;
}

.engrator-header h1 {
    font-size: 1.3em;
}

.engrator-header .col.right {
    margin-left: auto;
}