.actions-list {
    display: flex;
    flex-direction: column;
}

.actions-list h2 {
    margin-bottom: 1rem;
}

.actions-list div {
    width: 100%;
    vertical-align: middle;
    font-weight: bold;
    height: 70px;
    padding: 1rem 1rem 0 1rem;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(218,218,218,1);
}

.actions-list div p {
    font-size: 0.8rem;
    font-weight: normal;
    margin: 0;
    padding: 0.5rem 0 0 0;
}

.actions-list div:hover, .software-list div.selected {
    background: rgba(123, 123, 123, 0.05);
    cursor: pointer;
}