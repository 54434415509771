.secondary-scene {
    background: #fff;
    z-index: 10000;
    position: absolute;
    left: 5rem;
    top: 3rem;
    right: 36rem;
    bottom: 6rem;
    padding: 3rem;
    box-shadow: rgba(var(--palette-neutral-20,200, 200, 200),1) -10px 8px 30px 0;
}

.secondary-scene .close-btn {
    cursor: pointer;
}