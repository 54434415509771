.SnippetsList {
    padding: 2rem 2rem;
}
.SnippetsList .snippet-container .snippet-tile {
    float: left;
    width: 190px;
}
.SnippetsList .snippet-container span.index {
    position: absolute;
    right: 8px;
    font-size: 12px;
    top: 5px;
    z-index: 1;
    color: rgba(0, 0, 0, 0.7);
}
.SnippetsList .snippet-container {
    /*padding: 4rem 3rem;*/
    width: 300px;
    position: relative;
    /*float: left;*/
    margin: 0px auto;
    /*border: 1px dashed rgba(var(--palette-neutral-30,166, 166, 166),1);*/
}
.SnippetsList .snippet-container:last-child {
    /* width: auto; */
}
.SnippetsList .snippet-container .icon {
    position: absolute;
    display: block;
    opacity: 0.5;
    right: 0.5rem;
    top: 0.5rem;
}
.SnippetsList .divider {
    clear: both;
}
.SnippetsList .join-line {
    width: 1px;
    height: 40px;
    margin: auto;
    content: " ";
    display: block;
    /*margin-top: 120px;*/
    border-right: 1px solid rgba(0, 0, 0, 0.5);
}
.SnippetsList .join-line.blank {
    border-top: none;
}

.SnippetsList .snippet {
    position: relative;
    border-radius: 10px;
    background: #fff;
    border: 1px solid rgba(var(--palette-neutral-30,166, 166, 166),1);
    padding: 10px 15px;
    width: 100%;
    min-height: 110px;
    box-sizing: border-box;
    margin: auto;
    box-shadow: 0 0 10px 0 rgba(var(--palette-neutral-20,200, 200, 200),1);
}

.SnippetsList .snippet:hover {
    background: #fff;
    border: 1px solid #1366a5;
    /*cursor: pointer;*/
}

.SnippetsList .snippet.debugging-idle {
    opacity: 0.5;
}
.SnippetsList .snippet.debugging-success {
    border: 1px solid #3f704d;
    border-top: 3px solid #3f704d;
}

.SnippetsList .snippet.debugging-in-progress {
    border: 1px solid #ffe119;
    border-top: 3px solid #ffe119;
}

.SnippetsList .snippet p {
    margin: 0.5rem 0 0 0;
}

.SnippetsList .snippet .software {
    font-size: 1rem;
    margin-top: 0;
    display: flex;
    align-items: center;
    vertical-align: center;
    margin-bottom: 5px;
    color: rgba(0, 0, 0, 0.7);
}

.SnippetsList .snippet .software span {
    padding-left: 1rem;
}

.SnippetsList .snippet .title {
    font-weight: bold;
    font-size: 13px;
    word-break: break-word;
}

.SnippetsList .artifact {
    position: relative;
    padding: 0.5rem 0.5rem 0.5rem 35px;
    font-size: 0.8rem;
    font-family: 'medium';
    margin: 0.75rem 0;
}

.SnippetsList .artifact .icon {
    left: 15px;
}

.SnippetsList .snippet-container .debugging-report {
    position: relative;
    padding-left: 1rem;
    max-width: 8rem;
    border: 1px solid rgba(var(--palette-neutral-30,166, 166, 166),1);;
    padding: 0.5rem 1.5rem;
    margin: 1rem auto;
    cursor: pointer;
}
.SnippetsList .snippet-container .debugging-report .report-icon {
    position: absolute;
    left: 0.5rem;
    opacity: 0.5;
}