.engrator-page {
    /*padding: 2rem 4rem;*/
    padding: 35px;
    animation: pageFadeIn 0.2s;
}
.engrator-page h1 {
    margin-bottom: 10px;
}

@keyframes pageFadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.engrator-page.no-padding-page {
    padding: 0;
}
.content > .engrator-page.vertical-menu,
body.vertical-menu .content > .engrator-page {
    padding-left: 200px;
}
