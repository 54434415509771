.engrator-buttons-bar {
    display: flex;
    align-items: center;
    margin: 0;
    margin-top: 20px;
}

.engrator-buttons-bar .col.left {
    flex-grow: 1;
    text-align: right;
}

.engrator-buttons-bar .col.left .button-container {
    float: right;
    margin-left: 1rem;
}

.engrator-buttons-bar .col.right {
    margin-left: 3rem;
}