.SceneContainer {
    /*background-color: rgba(var(--palette-neutral-4,244, 244, 244),1);*/
    border-top: 1px solid;
    border-top-color: rgba(218,218,218,1);
    /*border-top-color: rgba(var(--palette-neutral-10,218, 218, 218),1);*/
    position: absolute;
    top: 120px;
    bottom: 0;
    left: 0;
    right: 0;
    /*padding-top: 100px;*/
}

.SceneContainer .shadowing {
    display: none;
}
.SceneContainer.right-panel-active .shadowing {
    /*background: rgba(255, 255, 255, 0.75);*/
    position: absolute;
    display: block;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
}
.steps-list-background {
    background: rgba(255, 255, 255, 0.5);
    padding-left: 0px;
    background-image: radial-gradient(rgba(0,0,0, 0.3) 1px, transparent 0);
    background-size: 30px 30px;
    background-position: -19px -19px;
}
.vertical-menu .steps-list-background {
    padding-left: 225px;
}