.connection-dropdown {
    display: flex;
    flex-direction: column;
}

.connection-dropdown select {
    width: 100%;
}
.connection-dropdown p.create-new {
    margin-top: 2rem;
}
.connection-dropdown .button-container button {
    float: right;
    margin-top: 1rem;
}