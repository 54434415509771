.designer-top .flex {
    display: flex;
    height: 60px;
    margin-top: 10px;
}

.designer-top .left {
    flex: 40%;
}
.designer-top .right {
    flex: 60%;
}

.designer-top .right,
.designer-top .left {
    position: relative;
}
.designer-top .left .content {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 1rem;
}
.designer-top .right .toolbar {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    display: flex;
}